<div class="account-details"[ngClass]="{'blur': isModalOpen}">
    <div class="div">

        <div class="frame">
            <p class="text-wrapper">Joined on {{createdDate}}</p>
            
            <div class="image">
                <label class="hoverable" for="fileInput">
                  <img style ="width:100%"[src]="url ? url : '../../assets/images/holder.png'" />
                 
                 
                </label>
                <br />
                <!-- <input
                  id="fileInput"
                  type="file"
                  [(ngModel)]="url"
                  class="select-profile-picture"
                  (change)="onSelectFile($event)"
                />
               -->
              </div>
              <br /><button class="buttonProfile"  *ngIf="url" (click)="delete()">delete</button>
              <img class="loadercss" style="width : 24px; color:white;"
                *ngIf="isLoading" src="../../assets/images/Rolling-1s-200px (2).gif"> 
              <div class="text-wrapper-2">{{this.userDetails.name}}</div>
             
            
        </div>
        <div class="frame-2">
            <img class="loadercss" style="width : 24px; color:white;"
            *ngIf="isLoading" src="../../assets/images/Rolling-1s-200px (2).gif"> 
            <div class="text-wrapper-5">{{(this.subscriptionDetailCustomer?.length > 0 && this.subscriptionDetailCustomer[0]?.product_name) ? this.subscriptionDetailCustomer[0].product_name:'No Plan Active'}}</div>
            <div  class="text-wrapper-6"*ngIf="subscriptionPeriod() !== 'day'  "><span  [ngStyle]="{
                color: (this.subscriptionDetailCustomer?.length > 0 && this.subscriptionDetailCustomer[0].duration_days > 0) ? 'inherit' : 'red'
              }">{{(this.subscriptionDetailCustomer?.length > 0 && this.subscriptionDetailCustomer[0].duration_days > 0) ? (( this.subscriptionDetailCustomer[0].status == 'active')?this.subscriptionDetailCustomer[0].duration_days + ' days left in subscription':this.subscriptionDetailCustomer[0].duration_days + ' days left in trial') :'No Subscription'}}</span> <br><a style="font-size: 12px; color: #ffffff;"> Plan {{(this.subscriptionDetailCustomer?.length > 0 )? this.subscriptionDetailCustomer[0].status:'not active' }}</a></div>
            <div  class="text-wrapper-6"*ngIf="subscriptionPeriod() == 'day'"><span  [ngStyle]="{
                color: (this.subscriptionDetailCustomer?.length > 0 && this.subscriptionDetailCustomer[0].duration_days > 0) ? 'inherit' : 'red'}">{{(this.subscriptionDetailCustomer?.length > 0 && this.subscriptionDetailCustomer[0].duration_days ) ? this.subscriptionDetailCustomer[0].duration_days +' day left' :'No Subscription'}}  </span><br><a style="font-size: 12px;"> Plan {{(this.subscriptionDetailCustomer?.length > 0 )? this.subscriptionDetailCustomer[0].status:'Not active' }}</a></div>
            <!-- <div  class="text-wrapper-6" > </div> -->
            <!-- <div *ngIf="!daysLeft" class="text-wrapper-6"> No active plan </div> -->
            <p class="element-month" *ngIf="this.subscriptionDetailCustomer?.length > 0" >
                <span class="span">$</span>
                <span class="text-wrapper-7">{{(this.subscriptionDetailCustomer?.length > 0 && this.subscriptionDetailCustomer[0].price)? this.subscriptionDetailCustomer[0].price:''}}</span>
                <span class="span">/{{
                    subscriptionPeriod()
                  }}</span>
            </p>
            <button *ngIf=" this.buttonName == 'Cancel' &&  this.subscriptionDetailCustomer[0]?.product_name !== 'Silver' && this.subscriptionDetailCustomer[0].is_canceled == false " class="button" (click)="can()"><button class="button-2"> <img class="" style="width : 24px; color:white;"
                *ngIf="isLoadingcancel" src="../../assets/images/Rolling-1s-200px (2).gif">Cancel</button></button>
            <div *ngIf="this.subscriptionDetailCustomer">
            <button *ngIf="this.subscriptionDetailCustomer?.length == 0  ||  this.subscriptionDetailCustomer[0].is_canceled == true || this.subscriptionDetailCustomer[0]?.is_valid == false" class="button" (click)="Subscription()"><button class="button-2">Choose Plan</button></button>
            <button *ngIf="this.subscriptionDetailCustomer[0]?.is_valid == false || 'this.subscriptionDetailCustomer[0].duration_days == null || this.subscriptionDetailCustomer[0].duration_days == 0' &&  this.subscriptionDetailCustomer[0]?.product_name == 'Silver'" class="button" (click)="Subscription()"><button class="button-2">Choose Plan</button></button>
        </div>
            <!-- <button *ngIf=" this.buttonName == 'Renew'" class="button" (click)="Subscription()"><button class="button-2">Renew Plan</button></button> -->
            <!-- <button class="button-wrapper"><button class="button-2">Upgrade</button></button> -->
            <!-- <div class="text-wrapper-8">{{daysLeft}} days left for free trial</div> -->
            <div class="text-wrapper-9">Membership Detail</div>
        </div>

        <div class="frame-3">
            <img class="loadercss" style="width : 24px; color:white;"
            *ngIf="isLoading" src="../../assets/images/Rolling-1s-200px (2).gif"> 
            <div id="tabContents">
                <div id="accountDetails" class="tab-content" [class.active-content]="activeTab === 'accountDetails'">



                
                    <form  *ngIf="!isLoading" class="mt-3" [formGroup]="editProfileForm" >
                    <div class="container">
                        <br>
                        <div class="row mt-2">
                            <div class="col-md-5">
                                <div class="mb-3">
                                    <input type="text"  *ngIf="!edit" class="form-control fontss" formControlName="first_name" name="first_name" [attr.disabled]="true" placeholder="First name">
                                    <input type="text"  *ngIf="edit" class="form-control fontss" formControlName="first_name" name="first_name" placeholder="First name">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="mb-3">
                                    <input type="text"  *ngIf="!edit" class="form-control fontss"  formControlName="last_name" name="last_name" [attr.disabled]="true" placeholder="Last name">
                                    <input type="text"  *ngIf="edit" class="form-control fontss" formControlName="last_name" name="last_name" placeholder="Last name">
                                </div>
                            </div>

                            <div class="col-md-5">
                                <div class="mb-3">
                                    <input type="text" class="form-control fontss"  [attr.disabled]="true" formControlName="editEmail" name="editEmail" placeholder="Email">
                                </div>
                            </div>

                            <!-- <div class="col-md-5">
                                <div class="mb-3">
                                    <input class="input-field form-control  fontss" *ngIf="!edit" [attr.disabled]="true"  formControlName="dob" name="dob" type="text"
                                     placeholder="DOB">
                                    <input class="input-field  form-control fontss" *ngIf="edit" formControlName="dob" name="dob" type="text" onfocus="(this.type='date')"
                                    onblur="(this.type='text')" placeholder="DOB">
                                </div>
                            </div> -->


                            <!-- <div class="col-md-5">
                                <div class="mb-3">
                                    <select class="form-control fontss"  *ngIf="!edit" name="school" [attr.disabled]="true" formControlName="school">
                                        <option value=" ">Select option
                                        </option>
                                        <option value="Sierra Canyon">Sierra Canyon</option>
                                        <option value="UAB">UAB</option>
                                        <option value="HCA">HCA</option>
                                        <option value="ASCP">ASCP</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    <select class="form-control fontss"  *ngIf="edit" name="school" formControlName="school">
                                        <option value="">Select option
                                        </option>
                                        <option value="Sierra Canyon">Sierra Canyon</option>
                                        <option value="UAB">UAB</option>
                                        <option value="HCA">HCA</option>
                                        <option value="ASCP">ASCP</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div> -->

                            <div class="col-md-5"></div>
                            <br>
                            <div class="d"  >
                                <!-- <div class="row">
                                    <button class="changePassBtn"><button class="button-2">Save</button></button>
                                    <button class="changePassBtn"><button class="button-2">Cancel</button></button>
                                </div> -->
                              
                                <div class="row" *ngIf="edit">
                                    <div class="col-6">
                                        <button class="custom-btn" (click)="updateProfile()">
                                            <img class="" style="width : 24px; color:white;"
                  *ngIf="isLoading2" src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit"
                  *ngIf="!isLoading2">Save</span></button>
                                    </div>
                                    <div class="col-6">
                                        <button class="cancel-btn" (click)="editUser()">   Cancel</button>
                                    </div>
                                </div>

                                
                            </div>
                          
                        </div>
                    </div>
</form>


                </div>

                <!-- <div id="changePassword" class="tab-content" [class.active-content]="activeTab === 'changePassword'">
                    <br>
                    <div class="container">
                        <br>
                        <div class="row mt-2">
                            <div class="col-md-5">
                                <div class="mb-3">
                                    <input type="text" class="form-control" formControlName="f_name" name="f_name" placeholder="New Password">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="mb-3">
                                    <input type="text" class="form-control" id="input2" placeholder="Confirm Password">
                                </div>
                            </div>

                            <br><br>

                            <button class="custom-btn"><button class="button-2">Submit</button></button>


                        </div>
                    </div>
                </div> -->

            </div>

            <img *ngIf="activeTab === 'accountDetails'" class="line" src="../../assets/line-1.svg" />
            <!-- <img *ngIf="activeTab === 'changePassword'" class="line2" src="../../assets/line-1.svg" /> -->
            <button class="button-3" (click)="editUser()"><button class="button-4">Edit</button></button>
            <div class="text-wrapper-9" [class.active-tab]="activeTab === 'accountDetails'"
                (click)="setActiveTab('accountDetails')">Account Details</div>
            <!-- <div class="text-wrapper-11" [class.active-tab]="activeTab === 'changePassword'"
                (click)="setActiveTab('changePassword')">Change Password</div> -->
        </div>


        <div class="frame-4 ">
            <img class="loadercss" style="width : 24px; color:white;"
            *ngIf="isLoading" src="../../assets/images/Rolling-1s-200px (2).gif"> 
            <div class="text-wrapper-9">Order History</div> 
            <!-- {{ userDetails.orderHistory | json }}
            <div class="group" *ngFor="let order of  userOrderFull.orderHistory" >
                
                <p><strong>Plan:</strong> {{ order.plan }}</p>
                <p><strong>Price ID:</strong> {{ order.price_id }}</p>
                <p><strong>Created At:</strong> {{ order.created_at }}</p>
                <p><strong>Price Amount:</strong> {{ order.price_amount }}</p>
                <p><strong>Expires At:</strong> {{ order.expire_at }}</p>
                <p><strong>Enroll Status:</strong> {{ order.sc_enroll }}</p>
                <p><strong>Product ID:</strong> {{ order.prod_id }}</p>
              </div> -->
             <!-- {{this.subscriptionDetailCustomer|json}} -->
              <div class="group" *ngIf="this.subscriptionDetailCustomer?.length > 0">
              <div *ngFor="let order of  this.subscriptionDetailCustomer">
            <div  >
                <div class="productname" >{{ order.product_name }}</div>
                <div class="pricedate" >{{ order.activation_date | date:'dd MMM yyyy' }}</div>
                <!-- <div class="text-wrapper-14">{{ order.price_id }}</div> -->
                <div class = "price">${{ order.price }}</div>
                <img class = "imgplan" src="../../assets/arrow-circle-up-right-2.svg" />
            </div>
            </div>
            </div>
            <!-- <div class="group-2">
                <div class="text-wrapper-12">Unlimited Plan</div>
                <div class="text-wrapper-13">20 Aug 2024</div>
                <div class="text-wrapper-14">Debited</div>
                <div class="text-wrapper-15">$119</div>
                <img class="img-2" src="../../assets/info-circle-1.svg" />
            </div>
            <div class="group-3">
                <div class="text-wrapper-12">Limited Plan</div>
                <div class="text-wrapper-13">10 Oct 2023</div>
                <div class="text-wrapper-14">Debited</div>
                <div class="text-wrapper-16">$75</div>
                <img class="img-2" src="../../assets/arrow-circle-up-right-2.svg" />
            </div> -->
            <div class=" nosubscribe"*ngIf="this.subscriptionDetailCustomer?.length  == 0">
                <a style="">No subscription</a>
                </div>
            <!-- <div class="text-wrapper-9">Order History</div> -->
        </div>

    </div>
</div>
<br><br>
<!-- <div class="content" [ngClass]="{'blur': isModalOpen}">
    <button (click)="openModal()">Open Success Modal</button>
  </div> -->
  
  <!-- Modal Structure confirmation -->
  <div class="modal" *ngIf="isModalOpen">
    <div class="modal-content">
        <span class="close-icon" (click)="closeModal()">&times;</span> <!-- Close Icon -->
      <h5 style="color :black;  text-align: center;">Are You Sure?</h5>
      <p class="modalp">you are about to cancel your subscription</p>
      <div class="buttoncontainer">
      <button  class ="modalbutton" (click)="confirmCancellation()"> <img class="" style="width : 24px; color:white;"
        *ngIf="isLoading2" src="../../assets/images/Rolling-1s-200px (2).gif">Yes</button>
      <button  class ="modalbutton" (click)="closeModal()">No</button>
      </div>
    </div>
  </div> 

  <!-- cancelled successfully -->
  <div class="modal" *ngIf="modalSuccess">
    <div class="modal-content">
        <span class="close-icon" (click)="navigatetoProfile()">&times;</span> <!-- Close Icon -->
        <img src="../../assets/icons/doneblue.png" alt="Success" class="modal-image" />
      <h5 class="modal-heading" style="color :black;  text-align: center;">Cancelled Successfully!</h5>
      <p class="modalp">
        Your current subscription is cancelled successfully</p>
     
    </div>
  </div>
  <div class="modal" *ngIf="modalSuccessProfile">
    <div class="modal-content">
        <span class="close-icon" (click)="closeModal()">&times;</span> <!-- Close Icon -->
        <img src="../../assets/icons/doneblue.png" alt="Success" class="modal-image" />
      <h5 class="modal-heading" style="color :black;  text-align: center;">Profile Updated Successfully!</h5>
     
     
    </div>
</div>
<div class="overlay" *ngIf="!newLoader"></div>
<div class="loadercard"  *ngIf="!newLoader">
  <p style="display: flex;align-items: center;color: rgb(71, 70, 70);margin-top: 20px;">
  <span class="loaderssss"></span> &nbsp; Loading ....
  </p>
</div>