import { Component, ElementRef, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  team_member: any = [
    {
      image: "../../assets/about-us/dr.everest.png",
      name: "Dr. Michael Everest",
      designation: "Founder and Chief Executive Officer",
      content:`With a dual doctorate including a Ph.D. in education, Dr. Everest has dedicated his life to making a difference in the world.<br><br>

      Using just a $1,500 loan, Dr. Everest founded Residents Medical, a company that quickly skyrocketed to success, generating high seven-figure revenues in its first year alone. Driven by his desire to revolutionize education, Dr. Everest went on to found edYOU Technologies.<br><br>
      
      In a mere 180 days, Dr. Everest and his team developed a groundbreaking conversational AI Being™ application that is changing the way education is improving its learning outcomes. This revolutionary technology is transforming the way we learn and work, providing a safe and 
      interactive environment where students and educators thrive, with an emphasis on wellness. Dr. Everest firmly believes that the future of AI technology lies in the hands of diligent stewards who can use it to help humans achieve more. Dr. Everest is also the Chairman Emeritus of Everest Family Foundation, a non-profit dedicated to supporting vulnerable populations and medical research.<br>`
    },
    {
      image: "../../assets/about-us/Owain.png",
      name: "Owain Yeoman",
      designation: "Chief Commercial Officer",
      content : `With over 20 years experience blending creative arts and technology, Owain Yeoman serves as Chief Commercial Officer at edYOU Technologies.<br><br>

      As an award-winning actor and producer, he built a multi-million dollar business, contributing to globally successful media content recognized with Emmys, Golden Globes, and Oscars. Holding a BA and MA from Oxford University, a Graduate Diploma from The Royal Academy of Dramatic Art, and a BA from Kings College London, Owain combines creative expertise with strategic insight.<br><br>
      
      Coming from a family of educators, he is deeply committed to fostering a supportive learning environment where wellness is key. At edYOU, Owain leads the sales, marketing, and technical teams, driving innovation in AI learning and wellness while expanding the company’s presence across various industries.`
    },
    {
      image: "../../assets/about-us/Greg Cross.png",
      name: "Greg Cross",
      designation: "Senior Technology Advisor",
      content :`Greg Cross is a visionary entrepreneur and the co-founder of Soul Machines, a pioneering company at the forefront of creating lifelike digital humans powered by AI. Known for his ability to blend cutting-edge technology with human-centric design, Greg has transformed industries ranging from entertainment to education and healthcare.<br><br>

With a career spanning global leadership roles in tech innovation, Greg is celebrated for pushing boundaries in AI and redefining how humans interact with machines. His work at Soul Machines aims to make AI more relatable, accessible, and impactful, positioning him as a trailblazer in the future of human-machine collaboration. Greg Cross is the senior technical advisor on the edYOU board.`
    },
    {
      image: "../../assets/about-us/Keanna Lee 1.png",
      name: "Keanna Lee",
      designation: "Project Manager",
      content:"Keanna Lee plays a pivotal role in the development and oversight of edYOU’s application, collaborating with Dr. Everest to bring the platform’s vision to life. With a Bachelor of Science in Kinesiology from the University of San Francisco and expertise in project management, Keanna is dedicated to creating user-friendly, impactful solutions that prioritize wellness and foster a supportive learning environment.<br><br>As Project Manager, Keanna leads edYOU’s technical teams, driving innovation in AI learning and wellness solutions while expanding the company’s presence across industries. Her dedication to collaboration and innovation enables edYOU to continue to evolve as a cutting-edge educational platform that meets the needs of its growing community."
    },
    // {
    //   image: "../../assets/about-us/daniel.png",
    //   name: "Daniel Yunas",
    //   designation: "Chief Product Officer"
    // },
    {
      image: "../../assets/about-us/Tal Navarro.png",
      name: "Tal Navarro",
      designation: "Senior Marketing Advisor",
      content:"Tal Navarro is a Senior Marketing Advisor at edYOU, bringing years of expertise in digital marketing and entrepreneurship. From launching her first company in NYC at 21 years old to founding Social Lady, an international marketing agency, Tal has built a career on innovation and resilience. She has worked with global brands, managed social media campaigns, and trained marketing professionals, all while staying at the forefront of digital and AI advancements.<br><br>Passionate about helping others grow, Tal combines her unique perspective with a deep dedication to empowering businesses and individuals. At edYOU, she’s focused on driving growth and creating impactful marketing strategies to help the company reach its full potential."
    },

  ]

  Academic_board: any = [
    {
      image: "../../assets/about-us/dr-raj.png",
      name: "Dr. Raj Ramsamooj",
      designation: "Stanford University"
    },
    {
      image: "../../assets/about-us/Dr. Narat John.png",
      name: "Dr. Narat John",
      designation: "Harvard PhD Cornell MD"
    },
    // {
    //   image: "../../assets/about-us/Prof. Sos Eltis.png",
    //   name: "Prof. Sos Eltis",
    //   designation: "Oxford University"
    // },
    {
      image: "../../assets/about-us/dr.helen.png",
      name: "Dr. Helen Eastman",
      designation: "Oxford University"
    },
    {
      image: "../../assets/about-us/Dr. Aleena Khan.png",
      name: "Dr. Aleena Khan",
      designation: "USMLE Test Prep Specialist"
    },
    {
      image: "../../assets/about-us/Dr. Ryan Staude.png",
      name: "Dr. Ryan Staude",
      designation: "Cornell University"
    },
    {
      image: "../../assets/about-us/Matthew Macaluso, DO.png",
      name: "Dr. Matthew Macaluso",
      designation: "University of Alabama, Chairman, Department of Wellness"
    },

  ]



  selectedCard: any = null;
isPopupVisible: boolean = false; 
isPopupVisible2: boolean = false


  constructor(private el: ElementRef,private titleService: Title, private metaService: Meta) { 

  }

  ngOnInit(): void {
    const videoElement = this.el.nativeElement.querySelector('#backgroundVideo') as HTMLVideoElement;
  
    this.titleService.setTitle('edYOU - About Us');
    this.metaService.addTags([
      {name: 'description', content: 'Discover edYOU, the revolutionary platform combining safe Conversational AI Beings ® (CABs) with personalized tutoring to enhance learning for students, educators, and professionals. From test prep and academic improvement to professional certifications and AI-driven sports coaching, edYOU empowers individuals with data-driven insights to achieve their goals. Guided by expert advisors and driven by a mission to make learning accessible, joyful, and impactful, edYOU is shaping the future of education while giving back to underserved communities through the Pay It Forward pledge.'},
      {name: 'keywords', content: 'edYOU tutoring, Conversational AI Beings, personalized learning, AI tutoring platform, test prep solutions, professional certifications, AI sports coaching, educational technology, data-driven learning, Pay It Forward education, global learning accessibility, student privacy, academic advisory board, AI-driven education tools, CABs learning, innovative tutoring solutions, secure educational AI'},
    ])
   
    if (videoElement) {
      // Ensure the video meets autoplay policies
      videoElement.muted = true; // Required for autoplay in modern browsers
      videoElement.playsInline = true; // For mobile devices
      videoElement.autoplay = true;

      // Manually trigger playback
      videoElement
        .play()
        .then(() => {
          console.log('Video is playing.');
        })
        .catch((error) => {
          console.error('Video playback failed:', error);
        });
    }
  }

 

  selectCard(card: any) {
    this.selectedCard = card;
  }

  openPopup(value:any) {
    this.isPopupVisible = true; 
    this.selectCard(value)
  }

  closePopup() {
    this.isPopupVisible = false;
  }


  openPopup2(value:any) {
    this.isPopupVisible2 = true; 
    this.selectCard(value)
  }

  closePopup2() {
    this.isPopupVisible2 = false;
  }

}
