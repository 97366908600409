import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-labs',
  templateUrl: './labs.component.html',
  styleUrls: ['./labs.component.css']
})
export class LabsComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { 
    this.title.setTitle('edYOU - Labs');
    this.meta.addTags([
      {name: 'description', content: 'At edYOU Labs, innovation meets education. Our research and patented technologies redefine personalized learning through AI-driven solutions. Explore cutting-edge advancements like the Proprietary Ingestion Engine (PIE) and Intelligent Curation Engine (ICE), designed to transform massive data into engaging, ethical, and personalized student experiences. Discover groundbreaking studies on AI integration in education, including personalized learning for medical residents and interactive flipped classrooms. With a focus on transparency, ethics, and human-centered design, edYOU Labs is shaping the future of learning.'},
      {name: 'keywords', content: 'edYOU Labs, personalized learning, AI in education, proprietary technology, Intelligent Curation Engine, data ingestion, human-centered design, ethical AI, flipped classroom, medical residency learning, AI-driven teaching, transformative education, educational technology, interactive learning, student engagement, innovative learning platforms'},
    ])
  }

  ngOnInit(): void {
  }

}
