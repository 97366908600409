import { APP_INITIALIZER, ErrorHandler, NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { UneeqavatarComponent } from './uneeqavatar/uneeqavatar.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';

import { PdfViewerLoaderService } from './service/pdf-viewer-loader.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExtraOptions, Router, RouterModule } from "@angular/router"; // <-- Add RouterModule
import * as Sentry from "@sentry/angular-ivy";
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { CoursePageComponent } from './courses-page/course.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TestimonialCardComponent } from './testimonial-card/testimonial-card.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { LabsComponent } from './labs/labs.component';

 // Import the datepicker module
 import { PdfComponent } from './pdf/pdf.component';
import { PlaypptComponent } from './playppt/playppt.component';
import { EdyouaiComponent } from './edyouai/edyouai.component';
import { CourseDescriptionComponent } from './course-description/course-description.component';
import { B2bInstitutionComponent } from './b2b-institution/b2b-institution.component';
import { EnterpriseSolutionComponent } from './enterprise-solution/enterprise-solution.component';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled', // Enable fragment scrolling
  scrollPositionRestoration: 'enabled', // Restore scroll position on navigation
};

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    CoursePageComponent,
    FooterComponent,
    TestimonialCardComponent,
    NewsPageComponent,
    MainComponent,
    UneeqavatarComponent,
    AboutUsComponent,
    TermsComponent,
    PrivacyComponent,
    LabsComponent,
    LoginComponent,
    ProfileComponent,
    PdfComponent,
    PlaypptComponent,
    EdyouaiComponent,
    CourseDescriptionComponent,
    B2bInstitutionComponent,
    EnterpriseSolutionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    FormsModule,
    PdfViewerModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    RouterModule.forRoot([], routerOptions), // <-- Add RouterModule with routerOptions
    CommonModule ,
    FormsModule,
    ReactiveFormsModule,

   
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (loaderService: PdfViewerLoaderService) => () => loaderService.loadPdfViewer(),
      deps: [PdfViewerLoaderService,Sentry.TraceService],
      multi: true,
      
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
