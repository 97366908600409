
<div class="footer-container">
  <div class= "parent-container">
    <div class="container">
      <img class="logo" src="../../assets/logoSets/edyoulogo_footer.png" alt="Logo">
      <div class="content-box">
      <p>11766 Wilshire Blvd.<br> Suite #1100<br> Los Angeles, CA 90025</p>
      <p><a [href]="'tel:' + 310.982.6822"> 310.982.6822 </a></p>
      <div class ="social-media">
        <a href="https://www.facebook.com/edyoutechnologies" target="_blank" rel="noopener noreferrer">
          <img src="../../assets/icons/fb-footer.png" alt="Facebook Logo">
        </a>
        <a href="https://www.linkedin.com/company/edyou-educateyourself/" target="_blank" rel="noopener noreferrer">
          <img  src="../../assets/icons/linkin-footer.png" alt="Logo">
        </a>
        <a href="https://twitter.com/edYOU_Tech" target="_blank" rel="noopener noreferrer">
          <img  src="../../assets/newb2cmedia/Link - Twitter.png" alt="Logo">
        </a>
      <a  href="https://www.youtube.com/channel/UC5UWfQMemtMTsH1MTGxYNmw" target="_blank" rel="noopener noreferrer">
        <img  src="../../assets/icons/yt-footer.png" alt="Logo">
      </a>
      <a  href="https://www.instagram.com/edyoutechnologies/" target="_blank" rel="noopener noreferrer">
        <img  src="../../assets/icons/camera-footer.png" alt="Logo">
     </a>
      </div>
      <p>© 2025 edYOU All rights reserved.</p>  
      </div>
    </div>
    <div class="second-part">
    <div class="container-form">
    <form [formGroup]="newsletterForm" id="newsletter-form">
      <div class="newForm" >
              <h6 class="newformHeading">Subscribe for our newsletter</h6>
              <div class="inputcontainer">
                <input id="emailInput1" formControlName="email" type="text" placeholder="Email">
              </div> 
              <small *ngIf="email?.invalid && email?.touched && email?.value" class="text" style="color:#E74040;display: flex; ">Invalid email</small>
              <button [disabled]="isLoading" (click)="newslettersubmit()">
                      <img class="" style="width : 24px; color:white;" *ngIf="isLoading"
                      src="../../assets/Rolling-1s-200px (2).gif"> <span  *ngIf="!isLoading">Submit</span>
              </button> 
              <small *ngIf="onsuccess" class="text" style="color:#2DC071;display: flex; ">Success</small>
              <p class="helpmergin newformHeading">Empower Yourself &trade;</p>
        </div>
    </form>

    <!-- <div class="helpmergin newformHeading">
     <p class="newformHeading">Empower Yourself</p>

    </div> -->
</div>
 <div class="menu-container">
  <nav class="menu">
    <ul >
      <li class="menu-item">
        <a routerLink="/course"  class="menu-link" title="Personal">Personal</a>
      </li>
      <li class="menu-item">
        <a routerLink="/educational-institute"  class="menu-link" title="Educational Institutions">Educational Institutions</a>
      </li>
      <li class="menu-item">
        <a routerLink="/enterprise-solutions"  class="menu-link" title="Enterprise Solutions">Enterprise Solutions</a>
      </li>
      <!-- <li class="menu-item">
        <a routerLink="/players-pal" routerLinkActive="active" class="menu-link">Player’s Pal</a>
      </li> -->
      <li class="menu-item">
        <a routerLink="/course" class="menu-link" title="Courses">Courses</a>
      </li>
      
    </ul>
    <ul class="menu-list">
     
   
      <li class="menu-item">
        <a routerLink="/about-us"  class="menu-link" title="About Us">About Us</a>
      </li>
      <li class="menu-item">
        <a routerLink="/news"  class="menu-link" title="Media">Media</a>
      </li>
      <li class="menu-item">
        <a routerLink="/terms" class="menu-link" title="Terms">Terms</a>
      </li>
      <li class="menu-item">
        <a routerLink="/privacy"  class="menu-link" title="Privacy">Privacy</a>
      </li>
    </ul>
  </nav>
  
 </div>
</div>
</div>
</div>
