import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ColorChangeService } from '../service/color-change.service';
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs';
import { UserService } from '../service/user.service';
import { ChangeDetectorRef } from '@angular/core';

declare var jQuery: any;
@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css'],
  animations: [
    trigger('slideDownUp', [
      state('visible', style({
        height: '100%'
      })),
      transition(':enter', [
        style({
          height: '0'
        }),
        animate('0.3s ease')
      ]),
      transition(':leave', [
        animate('0.3s ease', style({
          height: '0'
        }))
      ])
    ])
  ]
})
export class NavigationBarComponent implements OnInit {
  showMenu: boolean = false;
  isbuttondisabled: boolean = false;
  userName: any;
  user: any;
  mobileNav: boolean = true;
  enableB2cPlan: boolean = true;
  isb2b: boolean = false;
  backgroundColor: any ;
  constructor(private cd: ChangeDetectorRef,
    private router: Router, private service: ColorChangeService, private userservice: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { this.isBrowser = isPlatformBrowser(this.platformId); }
  scrollKey: any;
  private isBrowser: boolean;
  screenSize: string = 'large';
  isSafetyState = false;
  currentUrl: string = 'url';
  isScPage: boolean = false;
  sc: boolean = false;
  isLoggedIn: boolean = false;

  userOrderFull: any;
  userDetails: any;
  buttonName: any;
  updatedData: any

  scloggedin: boolean = false;
  b2cloggin: boolean = false;
  no_user_Loggedin: boolean = false;

  fordesktop: boolean = false;
  forMobile: boolean = false;
  forIpad: boolean = false;
  subscriptionDetailCustomer: any;
  subscriptionDetailinfo: any = [];

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      // Check if the current route is 'enterprise-solutions'
      if (this.router.url === '/enterprise-solutions') {
        this.isb2b = true;
      } else if (this.router.url === '/educational-institute') {
        this.isb2b = true;
      } else {
        this.isb2b = false;
      }
    });
    let bg = {
      background: 'linear-gradient(180deg, rgba(0, 3, 5, 0.7) 0%, rgba(34, 34, 34, 0.5) 100%)',
      boxShadow:' 0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
      // background: 'transparent',
      // boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12)',
    };
    this.backgroundColor = bg
    if(this.isBrowser){
    const width = window.innerWidth;
    if (width < 624) {
      this.screenSize = 'small'; // Mobile

    } else {
      this.screenSize = 'large'; // Desktop
    }

    }
    if (isPlatformBrowser(this.platformId)) {
  
    this.user = JSON.parse(localStorage.getItem('user') || '{}')
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Navigation is starting 
        if (event.url === '/' || event.url == '/educational-institute' || event.url == '/enterprise-solutions' ) {
          let bg = {
            background: 'linear-gradient(180deg, rgba(0, 3, 5, 0.7) 0%, rgba(34, 34, 34, 0.5) 100%)',
            boxShadow:' 0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
           
          };
          this.backgroundColor = bg
        } else { 
          let bg = {
            background: 'linear-gradient(180deg, rgba(0, 3, 5, 0.7) 0%, rgba(34, 34, 34, 0.5) 100%)',
            boxShadow:' 0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
           
          };
          this.backgroundColor = bg;

        }
        this.currentUrl = this.router.url;
       
        this.hideNavigation()
      }

      if (event instanceof NavigationEnd) {
        // Navigation is starting
        this.currentUrl = this.router.url;

        // Check if the URL contains '/safety'
        if (this.router.url.includes('/safety') || this.router.url.includes('/terms') || this.router.url.includes('/privacy')) {
          this.isSafetyState = true;
          //  console.log('u')
        } else {
          this.isSafetyState = false;
        }
        if (this.router.url.includes('/SC') || this.router.url.includes('/sc')) {
          this.isScPage = true;
          this.forMobile = false
          if (this.screenSize == 'small') {
            this.sc = true;
          } else {
            this.sc = false;
          }
        } else {
          if (this.screenSize == 'small') {
            this.sc = false;
          }
          this.isScPage = false;
          this.forMobile = true
          this.sc = false;
        }

        
        this.hideNavigation()
      }
     
    });



    //Maintaining state of user if login 
    this.userservice.loggedIn$.subscribe(state => {
      this.checkUserLogin()
      const storedUserData = localStorage.getItem('user');
      if (storedUserData) {
        this.updatedData = JSON.parse(storedUserData);
        this.userName = this.updatedData.f_name + " " + this.updatedData.l_name;

      }
       this.cd.detectChanges();
  })
   //Maintaining subscription details if user is SC can direct learn 
  this.userservice.subscription$.subscribe(subscriptionData => {
    this.subscriptionDetailinfo = subscriptionData || [];
  
    this.enableB2cPlan = localStorage.getItem("plan_type") === "true"
    if(this.enableB2cPlan){
    this.buttonName = this.subscriptionDetailinfo.subscription === false || this.subscriptionDetailinfo.length === 0 || this.subscriptionDetailinfo[0].is_valid == false ? "Startfree" : "Learn";
    }else{
      this.buttonName ='Learn'
    }
     this.cd.detectChanges(); // Update the UI accordingly
  });
   
  }

  navigateTOAboutUs() {
    this.toggleMenu()
    this.router.navigate(['/about-us'])
  }

  navigateToCourse() {
    this.toggleMenu()
    this.router.navigate(['/course'])

  }

  navigateToNews() {
    this.toggleMenu()
    this.router.navigate(['/news'])

  }


  homePage() {
    this.router.navigate(['/'])
  }

  toggleMenu() {
    const menu: any = document.getElementById("menu");

    menu.classList.toggle("show");
  }

  hideNavigation() {
    if (this.isBrowser) {
      const targetDiv = document.getElementById('targetDiv');
      if (targetDiv) {
        targetDiv.style.minHeight = '0';
        targetDiv.style.maxHeight = '0';
      }
    }
  }

  // using it to trigger  login popup
  openLoginPopup(event: any, isLogin: boolean): void {
    event.stopPropagation();
    this.userservice.showPopup(isLogin);
  }
  navigateTosubscription() {
    this.toggleMenu()
    this.router.navigate(['/course'], { fragment: 'pricing_section_id' }).then(() => {
      setTimeout(() => {
        const element = document.getElementById('pricing_section_id');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    });
  }

  logOut() {

    this.router.navigate(['/']);


    localStorage.removeItem('LoginState');
    localStorage.removeItem('email');
    localStorage.removeItem('user');
    localStorage.removeItem('url');
    localStorage.removeItem('Login_User');
    localStorage.removeItem('subscription');
    localStorage.removeItem("plan_type");
    localStorage.removeItem("PlanSelected");
    localStorage.removeItem("plan_b2b");
    localStorage.removeItem('tryuser');
    localStorage.removeItem('name');
    
    this.userservice.logout()
    this.isbuttondisabled = false
    // window.location.reload();


  }
  navigateToProfile() {
    this.menuToggle()
   
   this.router.navigate(['/profile']);
  
  }
  //This is to toggle menu in profile icon
  menuToggle(event?: MouseEvent) {
    this.enableB2cPlan = localStorage.getItem("plan_type") === "true"
    const toggleMenu: any = document.querySelector("#menuId");
    toggleMenu.classList.toggle("active");
    if (toggleMenu.classList.contains('active')) {
      // Add click event listener to the document to handle closing
      document.addEventListener('click', this.closeMenuOnClickOutside);
    } else {
      // If the menu is closed, remove the event listener
      document.removeEventListener('click', this.closeMenuOnClickOutside);
    }
   
  }
  closeMenuOnClickOutside = (event: MouseEvent) => {
    const toggleMenu: any = document.querySelector("#menuId");
    const profile: any = document.querySelector(".profile");

    // Check if the click is outside both the menu and profile
    if (!toggleMenu.contains(event.target as Node) && !profile.contains(event.target as Node)) {
      toggleMenu.classList.remove('active');

      // Remove the event listener once the menu is closed
      document.removeEventListener('click', this.closeMenuOnClickOutside);
    }
  }

// Api to get profile info
  getProfileDetail() {
    let createToken = {
      // token: this.token,
      email: this.user.email,
    };
    this.userservice.getProfileAPI(createToken).subscribe((res: any) => {
      if (res.statusCode == 200) {

        this.userOrderFull = res;
        this.userDetails = res.data;


        console.log(" this.userDetails", this.userDetails)

      } else {

      }
    })
    this.cd.detectChanges();
  }


  //App link from which user can start learning
  linkToedyouUser() {
   
   
      if(this.isBrowser){
      // window.location.href = 
      // window.open('https://smdev.edyou.com/signup', '_blank');
      window.open('https://ever-app.edyou.com/signup','_blank');
      }
    
    }

  //User Detail 
  checkUserLogin() {
    const storedLoginState = localStorage.getItem('LoginState');

    // Parse the JSON string to a boolean value
    const isLoggedIn = storedLoginState ? JSON.parse(storedLoginState) : false;
  
    // Check the login state and perform the corresponding action
    if (isLoggedIn == true) {
      this.getProfileDetail
      
      this.isLoggedIn = true
      

    } else {
      this.isLoggedIn = false
      this.logOut
      this.user = JSON.parse(localStorage.getItem('user') || '{}')
      this.userservice.loggedIn$.subscribe(state => {

        this.isLoggedIn = state;
        
      });
    }
  
    if (isLoggedIn == true) {

      this.no_user_Loggedin = false
    }

    else {
      this.no_user_Loggedin = true
    }
  }
  // calendly link 
  calendly() {
    if(this.isBrowser){
    window.open(this.userservice.salesLink());
    }
  }
  navigateToever(){
    this.toggleMenu()
    if(this.isBrowser){
      // window.location.href = 
      // window.open('https://smdev.edyou.com/signup', '_blank');
      window.open('https://ever-app.edyou.com','_blank');
      }
  }

}