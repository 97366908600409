<div class="d-flex justify-content-center">

    <div class="boxPricing">
        <span><img src="../../assets/successPayment.png" class="succesImg"></span><br><br>
        <span class="MainHeading ">Payment Successful</span> <br>
        <span class="subHeading">For any issues, please call us at +1 310-982-6822 <br> or <br>email us at <a href="mailto:support@edyou.com" target="_blank"> support&#64;edyou.com</a></span>
   
        <br><br>
        <span class="d-flex justify-content-center">
            <button class="btnHome" (click)="linkToedyouUser()"><span class="textbtn">Go To Dashboard</span></button>
            <!-- <button class="btnHome" (click)="navigateToMain()"><span class="textbtn"> Start Learning</span></button> -->
        </span>

    </div>
</div>