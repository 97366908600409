<div class="d-flex justify-content-center">

    <div class="boxPricing">
        <span><img src="../../assets/successPayment.png" class="succesImg"></span><br><br>
        <span class="MainHeading ">Thank you for approving your child’s edYOU account.</span> <br>
     
        <br><br>
        <span class="d-flex justify-content-center">
            <button class="btnHome" (click)="navigateToMain()"><span class="textbtn"> Go to Home</span></button>
        </span>

        
    </div>
</div>