<div class="overlay" *ngIf="isOverlayActive ">

  <p *ngIf="isOverlayActive" style="color:white;margin-left:50%;margin-top:25%;font-size: 24px;">Loading....</p>
</div>

<div id="login" class="modal fade" role="dialog">

  <div class="modal-dialog">

    <!-- login  form-->
    <div class="modal-content" *ngIf="signInForm " style="    margin-top: 100px;">

      <div class="modal-body" *ngIf="!otreverify && !otpsuccessForminvalid ">
        <!-- <button style="border:none;"data-dismiss="modal" class="close" (click)="closePopup()">&times;</button> -->
        <a class="close-icon" (click)="closePopup()"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" fill="none">
            <path d="M17 7L7 17M7 7L17 17" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg></a>
        <!-- sign in form -->
        <div class="innerBox">


          <span class="heading">Login</span>



          <form class="mt-3" [formGroup]="form">


            <div class="input-container">

              <input class="input-field" formControlName="email" name="email" type="text" placeholder="Email">
              <span class="emailicon">
                <img src="../../assets/icons/email-icon.svg">
              </span>
            </div>
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="label__error border__error">
              <div *ngIf="email.errors?.['required']">
                Email Address is required
              </div>
              <div *ngIf="email.errors?.['pattern']">
                Invalid Email Address

              </div>
            </div>

            <div class="input-container">
              <span style="z-index: 3333333;
              margin-right: -30px;
              margin-top: 5px;
              margin-left: 5px;">
                <img src="../../assets/icons/password-icon.svg">
              </span>
              <input class="input-field" type="password" [type]="show?'text':'password'" formControlName="password"
                name="password" placeholder="Password">
              <div class="input-group-prepend-login">
                <span class="input-group-text1" (click)="hideShow()">
                  <i style="margin-top:4px;margin-left: -19px; " [ngClass]="show?'fas fa-eye':'fas fa-eye-slash'"></i>
                </span>

              </div>
            </div>
            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="label__error border__error">
              <div *ngIf="password.errors?.['required']">
                Password is required
              </div>
            </div>
            <div class="forget-container">
              <a class="forget" style="cursor: pointer;" (click)="forgetFun()">Forgot Password?</a>
            </div>
            <!-- <a class="forget" style="cursor: pointer; margin-top: -1px;" (click)="forgetFun()">Forgot Password?</a> -->

            <button class="btn mt-2" (click)="login()"><img class="" style="width : 24px; color:white;"
                *ngIf="isLoading" src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit"  *ngIf="!isLoading">Sign
                In</span></button>
            <!-- toimplement -->
            <!-- *ngIf="!isLoading">Sign
                In</span></button> -->

          </form>
          <br>
          <span class="signup">Don't Have Account? <span style=" color: #46A6FF; cursor: pointer;;"
              (click)="signUpFun()">&nbsp; Sign Up </span></span>
        
          <div class="or">OR</div>
        

          <div class="sso">
            <!-- <img style="width:141px;" src="../../assets/icons/sso-apple.png"> -->
            <!-- <img  style="width:141px;" src="../../assets/icons/sso-google.png"> -->
            <!-- <button id="customGoogleSignInButton">Sign in with My Custom Text</button> -->
            <div >
              <div id="g_id_onload"
                data-client_id="514614298809-kf2itpfg57seg0577dhut5tb409btu01.apps.googleusercontent.com"
                data-context="continue_with" data-ux_mode="popup"
                data-login_uri="urlGoogle"
                data-auto_prompt="false" data-fedcm_opt_in="true">
              </div>

              <div class="g_id_signin" data-type="standard" data-shape="pill" data-theme="outline" data-text="continue_with "
                data-size="large" (click)="triggerGooglePrompt()" data-logo_alignment="left" >
              </div>
             
            </div>
          </div>
        </div>
        <span class="errorMessage mb-1" style="text-align: center;" *ngIf="errorMessage.length > 0">{{errorMessage}}
          <a *ngIf="(errorMessage == 'Account is not verified')||(errorMessage == 'OTP is not verified')"> , please
            click<span style=" color: #46A6FF; cursor: pointer;;" (click)="callapi()">&nbsp; Verify OTP
            </span></a></span>
        <!-- //  <span class="errorMessage mt-2"  *ngIf="errorMessage.length > 0" > </span> -->
      </div>
      <div class="modal-body" *ngIf="otreverify && !otpsuccessForminvalid ">

        <ng-container>
          <span class="heading">Enter OTP</span>

          <span class="subHeading mt-1">Please enter one time password sent to {{form.value.email}}</span>

          <div class="otp-field mt-31">
            <div class="otp-field mt-31">
              <form [formGroup]="otpForm" class="otp-fieldR mt-31" style="justify-content:center;">
                <input type="text" maxlength="1" formControlName="otp1" (keyup)="moveFocus($event, 'otp2')" />
                <input type="text" maxlength="1" formControlName="otp2" (keyup)="moveFocus($event, 'otp3') " />
                <input class="space" type="text" maxlength="1" formControlName="otp3"
                  (keyup)="moveFocus($event, 'otp4')" />
                <input type="text" maxlength="1" formControlName="otp4" (keyup)="moveFocus($event, 'otp5')" />
                <input type="text" maxlength="1" formControlName="otp5" (keyup)="moveFocus($event, 'otp6')" />
                <input type="text" maxlength="1" formControlName="otp6" />
              </form>
            </div>
            <!-- <form [formGroup]="otpForm" >
            <input type="text" maxlength="1" formControlName="otp1" (keyup)="moveFocus($event, 'otp2')" />
            <input type="text" maxlength="1" formControlName="otp2" (keyup)="moveFocus($event, 'otp3')" />
            <input class="space" type="text" maxlength="1" formControlName="otp3" (keyup)="moveFocus($event, 'otp4')" />
            <input type="text" maxlength="1" formControlName="otp4" (keyup)="moveFocus($event, 'otp5')" />
            <input type="text" maxlength="1" formControlName="otp5" (keyup)="moveFocus($event, 'otp6')" />
            <input type="text" maxlength="1" formControlName="otp6" />
            <button type="submit" class="btn btn-primary mt-3">Submit OTP</button>
          </form> -->
          </div>

          <br>
          <button class="btn" (click)="otpReVerify(false)"><img class="" style="width : 24px; color:white;"
              *ngIf="isLoading2" src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit"
              *ngIf="!isLoading2">Submit</span></button>

          <span class="signup mt-4"> <span style=" color: #46A6FF; cursor: pointer;;" (click)="callapi()">&nbsp;
              Back</span></span>

          <span class="successMessage mt-3" *ngIf="showMessage">{{showMessage}} Please log in to continue</span>

          <span class="errorMessage mt-2" *ngIf="errorMessage.length > 0">{{errorMessage}}</span>
          <span class="errorMessage mt-2" style="margin-bottom: -2px;" *ngIf="!errorMessage"> Didn't receive the OTP?
            Please check your Spam or Junk folder.</span>
        </ng-container>
      </div>
      <div class="modal-body" *ngIf="otpsuccessForm || otpsuccessForminvalid">
        <button data-dismiss="modal" class="close" (click)="closePopup()">&times;</button>
        <div class="innerBox1" *ngIf="otpsuccessForm">
          <!-- (click)="loginPopup()" -->
          <img style="width:80px" src="../../assets/icons/doneblue.png" alt="Success" class="modal-image" />
          <!-- <span class="successMessage mt-31" >Congratulation!<a style=" color: #46A6FF; cursor: pointer;;"
       
          >Sign Up Done Successfully</a></span> -->

          <span class="subHeading mt-1">{{this.showMessage}}</span>

        </div>

        <div class="innerBox1" *ngIf="otpsuccessForminvalid">

          <span><img style="width:50px;margin-bottom:-93px;" src="../../assets/failedPayment.png"
              class="succesImg"></span><br><br>
          <!-- <img style="width:50px"src="../../assets/icons/doneblue.png" alt="Success" class="modal-image" /> -->
          <span class="successMessage mt-3">Please try again</span>

          <span class="subHeading mt-1" style='color: red;'>{{this.errorMessage}}</span>

        </div>
      </div>
    </div>

    <!-- sign Up  form-->
    <div class="modal-content" *ngIf="signUpForm" style="margin-top: 40px;">
      <div class="modal-body">
        <!-- <button style="border:none;"data-dismiss="modal" class="close" (click)="closePopup()">&times;</button> -->
        <a class="close-icon" (click)="closePopup()"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" fill="none">
            <path d="M17 7L7 17M7 7L17 17" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg></a>
        <!-- sign up form -->
        <div class="innerBox">

          <ng-container *ngIf="!isSignUpFormSubmit && !otpsuccessForm && !otpsuccessForminvalid">
            <span class="heading">Create your edYOU account</span>

            <!-- <span class="subHeading mt-1" style="font-size :25px">Create your edYOU account</span> -->

            <form class="mt-3" [formGroup]="signUpform">


              <div class="input-container input-signup">
                <span class="input-icon">
                  <img src="../../assets/userIcon.svg">
                </span>
                <input class="input-field" formControlName="f_name" name="f_name" type="text" placeholder="First name">
              </div>
              <div *ngIf="fname.invalid && (fname.dirty || fname.touched)" class="label__error border__error">
                <div *ngIf="fname.errors?.['required']">
                  First name is required
                </div>

              </div>

              <div class="input-container input-signup">
                <span class="input-icon">
                  <img src="../../assets/userIcon.svg">
                </span>
                <input class="input-field" formControlName="l_name" name="l_name" type="text" placeholder="Last name">
              </div>
              <div *ngIf="lname.invalid && (lname.dirty || lname.touched)" class="label__error border__error">
                <div *ngIf="lname.errors?.['required']">
                  Last name is required
                </div>

              </div>

              <div class="input-container input-signup ">
                <span class="input-icon">
                  <img src="../../assets/icons/email-icon.svg">
                </span>
                <input style="margin-left:-7px" class="input-field" formControlName="email" name="email" type="text"
                  placeholder="E-mail Address">
              </div>
              <div *ngIf="emails.invalid && (emails.dirty || emails.touched)" class="label__error border__error">
                <div *ngIf="emails.errors?.['required']">
                  E-mail Address is required
                </div>
                <div *ngIf="emails.errors?.['pattern']">
                  Invalid E-mail Address

                </div>
              </div>




              <div class="input-container input-signup">
                <span class="input-icon">
                  <img src="../../assets/icons/password-icon.svg">
                </span>
                <input style="margin-left:-4px" class="input-field" type="password" [type]="show?'text':'password'"
                  formControlName="password" name="password" placeholder="Password">
                <div class="input-group-prepend-login">
                  <span class="input-group-text1" (click)="hideShow()">
                    <i style="margin-top:4px; margin-left: -16px;" [ngClass]="show?'fas fa-eye':'fas fa-eye-slash'"></i>
                  </span>

                </div>

              </div>
              <div *ngIf="passwordF.invalid && (passwordF.dirty || passwordF.touched)"
                class="label__error border__error">
                <div *ngIf="passwordF.errors?.['required']">
                  Password is required
                </div>
                <div *ngIf="passwordF.errors?.['minlength']">
                  Password minimum 8 character
                </div>
                <div *ngIf="passwordF?.errors?.['forbiddenPassword']">
                  <small>  Please do not use these characters (&=" '>< ?/)</small>
                </div>
              </div>


              <div *ngIf="showParent" class="input-container input-signup">
                <span class="input-icon">
                  <img src="../../assets/icons/email-icon.svg">
                </span>
                <input style="margin-left:-7px" class="input-field" formControlName="parentEmail" name="parentEmail"
                  type="text" placeholder="Parent's E-mail Address">
              </div>

              <div *ngIf="parentEmail.invalid  && showParent" class="label__error border__error">
                <div *ngIf="parentEmail.errors?.['required']">
                  <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <g clip-path="url(#clip0_13916_9946)">
                      <path d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z" stroke="#E74040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_13916_9946">
                        <rect width="12" height="12" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg> A parent’s email is required if you are under 13 years old
                  </span>
                </div>
                <div *ngIf="parentEmail.errors?.['pattern']">
                  Invalid E-mail Address
                </div>
              </div>

              <!-- parentemailend -->
              <!-- age-policy -->
              <!-- policy -->
              <div class="input-container input-signup">
                <span class="input-icon" style="margin-top: -14px;">
                  <span>
                    <input style="cursor: pointer;" type="checkbox" (change)="powerButtonpolicy13year($event)" formControlName="policyage"
                      name="policyage" id="termsCheckbox">
                  </span>
                </span>

                <div style="margin-left: 57px;">
                  <label for="termsCheckbox"
                    style=" color: #737373; font-size: 12px !important;line-height: 17px;  font-family: 'DM Sans';  margin-left: -22px;text-transform: none;">
                    &nbsp;I confirm that I am 13 years old or over

                  </label>
                </div>

              </div>
              <div *ngIf="policyage.invalid && (policyage.dirty || policyage.touched)"
                class="label__error border__error">
                <div *ngIf="policyage.errors?.['required']">
                
                </div>
              </div>
              <!-- policy -->

              <!-- policy -->
              <div class="input-container input-signup">
                <span class="input-icon" style="margin-top: -14px;">
                  <span>
                    <input style="cursor: pointer;" type="checkbox" (change)="powerButton($event)" formControlName="policy" name="policy"
                      id="termsCheckbox1">
                  </span>
                </span>

                <div style="margin-left: 57px;">
                  <label for="termsCheckbox"
                    style="  font-family: 'DM Sans'; color: #737373; font-size: 12px !important;line-height: 17px;  margin-left: -22px;text-transform: none;">
                    &nbsp;I accept and consent to the edYOU <b style="color: blue;">
                      <a routerLink="./terms" target="_blank" title="Terms & Conditions">Terms & Conditions</a>
                    </b>
                  </label>
                </div>

              </div>
              <div *ngIf="policy.invalid && (policy.dirty || policy.touched)" class="label__error border__error">
                <div *ngIf="policy.errors?.['required']">
                  Please agree to Terms & Conditions to continue
                </div>
              </div>
              <!-- policy -->

              <button class="btn " (click)="signUp()"><img class="" style="width : 24px; color:white;"
                  *ngIf="isLoading2" src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit"
                  *ngIf="!isLoading2">Sign
                  Up</span></button>

            </form>
            <br>

            <span class="signup">Already Have Account? <span style=" color: #46A6FF; cursor: pointer;;"
                (click)="signInFun()">&nbsp; Sign In</span></span>
          
            <div class="or">OR</div>
            
            <div class="sso">
              <!-- <img style="width:141px;" src="../../assets/icons/sso-apple.png"> -->
              <!-- <img  style="width:141px;" src="../../assets/icons/sso-google.png"> -->
              <div>
                <div id="g_id_onload"
                  data-client_id="514614298809-kf2itpfg57seg0577dhut5tb409btu01.apps.googleusercontent.com"
                  data-context="signin" data-ux_mode="popup"
                  data-login_uri="urlGoogle"
                  data-auto_prompt="false" data-fedcm_opt_in="true">
                </div>

                <div class="g_id_signin" data-type="standard" data-shape="pill" data-theme="outline" data-text="Google"
                  data-size="large" (click)="triggerGooglePrompt()" data-logo_alignment="left" >
                </div>
              </div>
            </div>

            <span class="successMessage mt-3" *ngIf="showMessage">{{showMessage}}</span>
            <span class="successMessage mt-3" style="color:red" *ngIf="signuperrormessage">{{signuperrormessage}}</span>
          </ng-container>

          <ng-container *ngIf="isSignUpFormSubmit && !otpsuccessForm && !otpsuccessForminvalid">
            <span class="heading">Enter OTP</span>

            <span class="subHeading mt-1">Please enter one time password sent to {{signUpform.value.email}}</span>

            <div class="otp-field mt-31">
              <div class="otp-field mt-31">
                <form [formGroup]="otpForm" class="otp-field mt-31">
                  <input type="text" maxlength="1" formControlName="otp1" (keyup)="moveFocus($event, 'otp2')" />
                  <input type="text" maxlength="1" formControlName="otp2" (keyup)="moveFocus($event, 'otp3') " />
                  <input class="space" type="text" maxlength="1" formControlName="otp3"
                    (keyup)="moveFocus($event, 'otp4')" />
                  <input type="text" maxlength="1" formControlName="otp4" (keyup)="moveFocus($event, 'otp5')" />
                  <input type="text" maxlength="1" formControlName="otp5" (keyup)="moveFocus($event, 'otp6')" />
                  <input type="text" maxlength="1" formControlName="otp6" />
                </form>
              </div>
              <!-- <form [formGroup]="otpForm" >
                <input type="text" maxlength="1" formControlName="otp1" (keyup)="moveFocus($event, 'otp2')" />
                <input type="text" maxlength="1" formControlName="otp2" (keyup)="moveFocus($event, 'otp3')" />
                <input class="space" type="text" maxlength="1" formControlName="otp3" (keyup)="moveFocus($event, 'otp4')" />
                <input type="text" maxlength="1" formControlName="otp4" (keyup)="moveFocus($event, 'otp5')" />
                <input type="text" maxlength="1" formControlName="otp5" (keyup)="moveFocus($event, 'otp6')" />
                <input type="text" maxlength="1" formControlName="otp6" />
                <button type="submit" class="btn btn-primary mt-3">Submit OTP</button>
              </form> -->
            </div>

            <br>
            <button class="btn" (click)="otpVerify(false)"><img class="" style="width : 24px; color:white;"
                *ngIf="isLoading2" src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit"
                *ngIf="!isLoading2">Submit</span></button>

            <span class="signup mt-4"> <span style=" color: #46A6FF; cursor: pointer;;" (click)="signInFun()">&nbsp;
                Back</span></span>

            <span class="successMessage mt-3" *ngIf="showMessage">{{showMessage}} Please log in to continue</span>

            <span class="errorMessage mt-2" *ngIf="errorMessage.length > 0">{{errorMessage}}   <a class="resendbtnColor" (click)="resendOtp(false)" [class.disabled]="isResendBtndisabled">&nbsp; <b>
              Resend</b></a> </span>
            <div class="d" style="display: flex; align-items: center;">
              <span class="spamMessage mt-2" style="margin-bottom: -2px;" *ngIf="!errorMessage"> Didn't receive the OTP?
                <a class="resendbtnColor" (click)="resendOtp(false)" [class.disabled]="isResendBtndisabled">&nbsp; <b>
                    Resend</b></a> <br> <b> Please check your Spam or Junk folder</b>.</span>
           
            </div>
          
          </ng-container>

          <!--success otp  -->




          <div class="innerBox1" *ngIf="otpsuccessForm">

            <img style="width:80px" src="../../assets/icons/doneblue.png" alt="Success" class="modal-image" />
            <span class="successMessage mt-1">
              Congratulations! </span>
            <!-- <span class="successMessage mt-31" >Please<a style=" color: #46A6FF; cursor: pointer;;"
            (click)="loginPopup()">Login</a>to continue</span> -->

            <span class="subHeading mt-1" style="width:330px">Your account has been successfully created. Please
              subscribe a package to start your learning.</span>

          </div>
          <div class="innerBox1" *ngIf="otpsuccessForminvalid">
            <span><img style="width:50px; margin-bottom: -93px;" src="../../assets/failedPayment.png"
                class="succesImg"></span><br><br>
            <!-- <img style="width:50px"src="../../assets/icons/doneblue.png" alt="Success" class="modal-image" /> -->
            <span class="successMessage mt-3">Please try again</span>

            <span class="subHeading mt-1" style='color: red;'>{{this.errorMessage}}</span>

          </div>
        </div>
      </div>
    </div>


    <!-- forget  form-->
    <div class="modal-content" *ngIf="forgetInForm" style="    margin-top: 100px;">
      <div class="modal-body">
        <a class="close-icon" (click)="closePopup()"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" fill="none">
            <path d="M17 7L7 17M7 7L17 17" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg></a>


        <!-- forget form -->
        <div class="innerBox">

          <ng-container *ngIf="!isForgetSubmitTrue ">
            <span class="heading">Forgot Password</span>

            <span class="subHeading mt-2">Please enter your email and we’ll send you the reset the password link</span>

            <form class="mt-4" [formGroup]="forgetForm">


              <div class="input-container">
                <span class="input-group-text fondo-icon">
                  <img src="../../assets/icons/email-icon.svg">
                </span>
                <input class="input-field" formControlName="email" name="email" type="text" placeholder="Email Address">
              </div>
              <div *ngIf="emailsforget.invalid && (emailsforget.dirty || emailsforget.touched)"
                class="label__error border__error">
                <div *ngIf="emailsforget.errors?.['required']">
                  E-mail Address is required
                </div>
                <div *ngIf="emailsforget.errors?.['pattern']">
                  Invalid E-mail Address

                </div>
              </div>


              <button class="btn" (click)="forgetSubmit()"> <img class="" style="width : 24px; color:white;"
                  *ngIf="forgetLoader" src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit"
                  *ngIf="!forgetLoader">Continue</span></button>

            </form>
            <br>
            <span class="signup">Back to<span style=" color: #46A6FF; cursor: pointer;;" (click)="signInFun()">&nbsp;
                Sign in </span></span>

            <span class="errorMessage mt-2" *ngIf="errorMessage.length > 0">{{errorMessage}}</span>
          </ng-container>

          <ng-container *ngIf="isForgetFormSubmit">
            <span class="heading">Enter OTP</span>

            <span class="subHeading mt-1">Please enter one time password sent to {{forgetForm.value.email}}</span>

            <div class="otp-field mt-31">
              <form [formGroup]="otpForm" class="otp-field mt-31">
                <input type="text" maxlength="1" formControlName="otp1" (keyup)="moveFocus($event, 'otp2')" />
                <input type="text" maxlength="1" formControlName="otp2" (keyup)="moveFocus($event, 'otp3')" />
                <input class="space" type="text" maxlength="1" formControlName="otp3"
                  (keyup)="moveFocus($event, 'otp4')" />
                <input type="text" maxlength="1" formControlName="otp4" (keyup)="moveFocus($event, 'otp5')" />
                <input type="text" maxlength="1" formControlName="otp5" (keyup)="moveFocus($event, 'otp6')" />
                <input type="text" maxlength="1" formControlName="otp6" />
              </form>
            </div>


            <!-- <a class=""     > <span class="normalMessage" >&nbsp;Didn't got the code? <a class="resendbtnColor" (click)="resendOtp()" [class.disabled]="isResendBtndisabled">&nbsp; Resend</a>
            </span></a> -->
            <br>
            <button class="btn" (click)="otpVerifyforget(true)"><img class="" style="width : 24px; color:white;"
                *ngIf="isLoading2" src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit"
                *ngIf="!isLoading2">Verify</span></button>

            <span class="signup mt-4"> <span style=" color: #46A6FF; cursor: pointer;;" (click)="signInFun()">&nbsp;
                Back</span></span>


            <div class="d" style="display: flex; align-items: center;">
              <span class="spamMessage mt-2" style="margin-bottom: -2px;" *ngIf="!errorMessage"> Didn't receive the OTP?
                <a class="resendbtnColor" (click)="resendOtp(true)" [class.disabled]="isResendBtndisabled">&nbsp; <b>
                    Resend</b></a> <br> <b> Please check your Spam or Junk folder</b>.</span>
            </div>

            <span class="successMessage mt-3" *ngIf="showMessage">{{showMessage}}</span>
            
            <span class="errorMessage mt-2" *ngIf="errorMessage.length > 0">{{errorMessage}}</span>

          </ng-container>

          <!-- <ng-container *ngIf="isForgetSubmitTrue">

            <div class="d d-flex justify-content-center">
              <img src="../../assets/success.png" class="successImg">
            </div>
            <br>
            <span class="heading">Link Sent</span>

            <span class="subHeading mt-1">We have sent you the reset password link to {{this.forgetForm.value.email}}</span>

            <br>
            <span class="signup">Back to<span style=" color: #46A6FF; cursor: pointer;;" (click)="signInFun()">&nbsp;
                Sign in </span></span>
          </ng-container> -->

          <ng-container *ngIf="resetPasswordActive && !otpsuccessForm">
            <span class="heading">Reset Password</span>

            <span class="subHeading mt-2">Please change your password</span>

            <form class="mt-4" [formGroup]="restPasswordForm">


              <div class="input-container">
                <span class="input-group-text fondo-icon">
                  <img src="../../assets/icons/password-icon.svg">
                </span>
                <input class="input-field" formControlName="newPassword" name="newPassword" type="text"
                  placeholder="New password">

              </div>
              <div *ngIf="newPassword.invalid &&  newPassword.touched" class="label__error border__error">
                <div *ngIf="newPassword.errors?.['required']">
                  Password is required
                </div>
                <div *ngIf="newPassword.errors?.['minlength']">
                  Password is required
                </div>
                <div *ngIf="newPassword?.errors?.['forbiddenPassword']">
                  <small>  Please do not use these characters (&=" '>< ?/)</small>
                </div>
              </div>

              <div class="input-container">
                <span class="input-group-text fondo-icon">
                  <img src="../../assets/icons/password-icon.svg">
                </span>
                <input class="input-field" formControlName="confirmPassword" name="confirmPassword" type="text"
                  placeholder="Confirm password">
              </div>
              <div *ngIf="confirmPassword.invalid &&  confirmPassword.touched" class="label__error border__error">
                <div *ngIf="confirmPassword.errors?.['required']">
                  Password is required
                </div>
                <div *ngIf="confirmPassword.errors?.['minlength']">
                  Password is required
                </div>
              
              </div>

              <div class="label__error" *ngIf="restPasswordForm.errors?.['failedToMatch']">
                Password and Confirm password does not match
              </div>


              <button class="btn" (click)="resetPassword()"> <img class="" style="width : 24px; color:white;"
                  *ngIf="resetLoader" src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit"
                  *ngIf="!resetLoader">Reset</span></button>

            </form>
            <br>
            <span class="successMessage mt-3" *ngIf="showMessage">{{showMessage}}</span>
            <!-- <span class="signup">Back to<span style=" color: #46A6FF; cursor: pointer;;" (click)="signInFun()">&nbsp;
                Sign in </span></span> -->

          </ng-container>


        </div>
        <div class="innerBox1" *ngIf="otpsuccessForm">

          <img style="width:80px" src="../../assets/icons/doneblue.png" alt="Success" class="modal-image" />
          <span class="successMessage mt-31">Success
          </span>

          <span class="subHeading mt-1">{{this.showMessage}}</span>

        </div>
        <div class="innerBox1" *ngIf="otpsuccessForminvalid">
          <span><img style="width:50px;" src="../../assets/failedPayment.png" class="succesImg"></span><br><br>
          <!-- <img style="width:50px"src="../../assets/icons/doneblue.png" alt="Success" class="modal-image" /> -->
          <span class="successMessage mt-3">Please try again</span>

          <span class="subHeading mt-1" style='color: red;'>{{this.errorMessage}}</span>

        </div>
      </div>
    </div>













  </div>