
<div class="d-flex justify-content-center">

    <div class="boxPricing">
        <span><img src="../../assets/successPayment.png" class="succesImg"></span><br><br>
        <span class="MainHeading ">Subscription Successful</span> <br>
        <span class="subHeadingMain mt-2">Thank you for subscribing to the Trailblazers Plan. </span><br>
        <span class="subHeading">You will receive a confirmation email. For any issues, kindly contact support&#64;edyou.com or 310.982.6822 </span>
        <br><br>
        <!-- <span class="amountPaid">Plan: {{plan}}</span><br>
        <span class="amountPaid">Amount Paid: ${{amount}}</span>
        <br> -->
        <br>
        <!-- <span class="subHeading">Invoice : <a (click)="downloadInvoice()">Click here</a></span><br> -->
        
        <span class="d-flex justify-content-center">
            <button class="btnHome" (click)="navigateToMain()"><span class="textbtn"> Go to Home</span></button>
        </span>

    </div>
</div>
