  <div class="testimonials-section">
    <h2>Testimonials</h2>
    <div class="slider">
      <div class="testimonials-container">
          <div class="testimonial-card" *ngFor="let testimonial of testimonials">
            <div class="stars">★★★★★</div>
            <p [innerHTML]="testimonial.text"></p>
            <h3>{{ testimonial.author }}</h3>
            <h4>{{ testimonial.role }}</h4>
          </div>
        </div>
    </div>
  </div>


  