import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input,Injector, ViewContainerRef, ComponentFactoryResolver, Compiler, OnChanges,Inject, OnDestroy, OnInit, Output, SimpleChanges,PLATFORM_ID , ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from 'src/app/service/restapi';
import { UserService } from 'src/app/service/user.service';
import { isPlatformBrowser } from '@angular/common';

import { PdfViewerLoaderService } from '../service/pdf-viewer-loader.service'; 
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css'],
  // changeDetection:ChangeDetectionStrategy.OnPush
})
export class PdfComponent implements OnInit, OnChanges, OnDestroy {
  isBrowser: boolean =false;
  pdfSrc: any = "";
  AuthToken: any;
  user: any;
  page: number = 1;
  totalPages: number = 0;
  isLoaded: any;
  @Input() pdf: any
  pdfUneeq: any = [];

  // @ViewChild(PdfComponent) pdfComponent: any;
 

  isLoaderActive: boolean = false
  isLoadingNext: boolean = false
  isLoadingPrevious: boolean = false
  isCommonDisableButton: boolean = false

  @Output("stopSubtitle") stopSubtitle: EventEmitter<any> = new EventEmitter();
  @Output("callTimer") callTimer: EventEmitter<any> = new EventEmitter();
  totalPagination: any = [];
  pvalue: any = '1';
  iconSize: any = 35
  downloadIconSize = 22
  refreshIconSize = 52
  isPauseON: boolean = false
  soulPersona: any = [];
  PresentName: any;
  pName: any;
  contentType: any;
  pdfSrctype: any;
  pdfYouTube: any;
 
  currentTime: any;
  intervalId: any;
  swalFireData:any;
  type:any;
  payload:any;
  isSwal:boolean = false;
  isPdfViewerLoaded: boolean =false;
  pdfViewerModule: any; // To hold the dynamically loaded module

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private compiler: Compiler, private viewContainerRef: ViewContainerRef, // Access to the view container for dynamic components
  private componentFactoryResolver: ComponentFactoryResolver,
  private injector: Injector,private pdfViewerLoaderService: PdfViewerLoaderService,private service: UserService, private sanitizer: DomSanitizer, private router: Router, private actRouter: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.loadPdfViewer();
    this.pdfViewerLoaderService.loadPdfViewer().then((module) => {
      if (module) {
        this.pdfViewerModule = module;
        this.isPdfViewerLoaded = true;
        this.createPdfViewerComponent();
       
      }
    }).catch((error) => {
      console.error('Failed to load ng2-pdf-viewer in component', error);
    });
  
    
    this.user = JSON.parse(localStorage.getItem('tryuser') || '[]')
    this.AuthToken = localStorage.getItem('token')
    // window.addEventListener('resize',  this.SvgImageSIzeChange);
   if( this.isBrowser ){ this.SvgImageSIzeChange()
    this.getPresentationDetials()
   }
  }
  ngAfterViewInit(): void {
    // This lifecycle hook ensures the view is ready for dynamic component insertion
    if (this.isPdfViewerLoaded) {
      this.createPdfViewerComponent();
    }
  }
  createPdfViewerComponent() {
    // Import PdfViewerComponent dynamically
    import('ng2-pdf-viewer').then((module) => {
      const PdfViewerComponent = module.PdfViewerComponent;
      
      // Create the component dynamically and inject it into the view container
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PdfViewerComponent);
      const componentRef = this.viewContainerRef.createComponent(componentFactory);

      // Set inputs for the PdfViewerComponent
      componentRef.instance.src = this.pdfSrc;
      componentRef.instance.page = this.page;
      componentRef.instance.renderText = true;
      componentRef.instance.src = this.pdfSrc;
      componentRef.instance.page = this.page;
      componentRef.instance.renderText = true;
      componentRef.instance.originalSize = false;
      componentRef.instance.fitToPage = false;
      componentRef.instance.zoom = 1;
      componentRef.instance.rotation = 0;
      componentRef.instance.showAll = false;
      componentRef.instance.zoomScale = 'page-width';
      componentRef.instance.autoresize = true;
   
    }).catch((error) => {
      console.error('Error dynamically creating PdfViewerComponent:', error);
    });
  }
  SvgImageSIzeChange() {
    if (window.screen.width < 480) {
      //  if ((localStorage.getItem('screen') === "LearningScreen")) {
      this.iconSize = 35
      this.downloadIconSize = 21
      this.refreshIconSize = 41
      //console.log('landscape')
      //  }
    } else {
      this.iconSize = 35
      this.downloadIconSize = 22
      this.refreshIconSize = 52
      // console.log('protrait')
    }
  }
  loadPdfViewer() {
    this.pdfViewerLoaderService.loadPdfViewer().then((pdfViewerModule) => {
      // Module loaded successfully
      this.isPdfViewerLoaded = true;
   
    }).catch((error) => {
      console.error('Failed to load ng2-pdf-viewer module:', error);
    });
  }

  clearPdfViewer() {
    //this.pdfSrc = "https://pollydemo2022.s3.us-west-2.amazonaws.com/Presentation/sample.pdf"
    this.pdfSrc = "undefined"
    this.page = 1
    this.totalPages = 0
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.totalPages = 0
    //this.getPresentationDetials()
    this.uneeqStopMethod()
    this.SvgImageSIzeChange()
  }


  uneeqStopMethod() {
    this.service.currentSharedData.subscribe((res: any) => {
      //  this.pdfUneeq = res
      this.soulPersona = res
    })
  }

  onError(error: any) {

    if (error.name == "MissingPDFException") {
      console.log('close loader')
      this.isLoaderActive = false
    }
  }
  

  // getPresentationDetials() {
  //   this.service.currentPDFLinkData.subscribe((res: any) => {
  //     this.pdfSrc = res.url 
  //     this.pName = res.heading
  //      console.log('data link=>>>', this.pdfSrc)
  //     this.isLoaderActive = true
  //   })
  //   this.pdf = ""
  // }
//mapping of pdf to be shown
  getPresentationDetials() {
  
    this.service.currentPDFLinkData.subscribe((res: any) => {
      this.contentType = res.type
        this.pvalue = "1"
        this.pdfSrc = res.url
        this.pName = res.heading
        this.isLoaderActive = true

      
    })
    // this.pdf = ""


  }

 


  
  // pdf viwer start --
  afterLoadComplete(pdfData: any) {
   
    this.isLoaderActive = false
    this.callTimer.emit()
    setTimeout(() => {
      this.isLoaderActive = false
      this.page = this.service.IndexLearningNo
      this.pvalue = this.service.IndexLearningNo
      this.totalPages = pdfData.numPages;
      this.counterForpagination(this.totalPages)
       }, 1000)
    
    this.sendData(this.page, "Start")
    
  }

  counterForpagination(total: any) {
    this.isLoaderActive = false
    this.totalPagination = []
    // Loop from 1 to 'totalPages' and push each number into the 'newArray'
    for (let i = 1; i <= total; i++) {
      this.totalPagination.push(i);
    }

  }


  getPaginationvalue(event: any) {
      console.log(event,'-------------------')
    this.pvalue = event
    this.page = event
    this.stopSubtitle.emit()
    this.soulPersona.stopSpeaking()
    this.isCommonDisableButton = true
    setTimeout(()=>{
      this.isCommonDisableButton = false 
    },5000)
    this.sendData(this.page, "jump")
    // this.sendValueToSoulMachine("jump", this.page)
    var d: any = document.getElementById('outputDesc')
    if (d)  d.innerHTML = '';
    const dropdown = document.querySelector('#dropdownMenuLink');
    if (dropdown) {
      (dropdown as HTMLElement).classList.remove('show');
    }
  
    const dropdownMenu = document.querySelector('.dropdown-menu');
    if (dropdownMenu) {
      (dropdownMenu as HTMLElement).classList.remove('show');
    }
  }

 


  getYouTubeEmbedUrl(videoId: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoId);

  }


  isImage(): boolean {
    return this.pdfSrctype === 'jpeg' || this.pdfSrctype === 'jpg' || this.pdfSrctype === 'png' || this.pdfSrctype === 'gif';
  }

  isVideo(): boolean {
   

    return this.pdfSrctype === 'mp4';
  }

  isYouTube(): boolean {
    return this.pdfSrctype === 'youtube';
  }


  // Function to navigate to the next page slide
  nextPage(event: any) {
    this.page++;
    this.pvalue = this.page;
    this.stopSubtitle.emit()
    this.isLoadingNext = false
    this.soulPersona.stopSpeaking()
    this.isCommonDisableButton = true
    this.isLoaderActive = false
    setTimeout(() => {
      this.isCommonDisableButton = false
    }, 5000)
       this.sendData(this.page, "next→")
    var d: any = document.getElementById('outputDesc')
    if (d) d.innerHTML = '';

  }


  // Function to navigate to the back page slide
  prevPage(event: any) {
    this.page--;
    this.pvalue = this.page;
    this.stopSubtitle.emit()
    this.isLoadingPrevious = false
    this.soulPersona.stopSpeaking()
    this.isCommonDisableButton = true
    this.isLoaderActive = false
    setTimeout(() => {
      this.isCommonDisableButton = false
    }, 5000)
    this.sendData(this.page, "←previous")
   // this.sendValueToSoulMachine("←previous", this.page)
    var d: any = document.getElementById('outputDesc')
    if (d) d.innerHTML = '';

  }


  // Function to send data with specified number and slide
  sendData(num: any, slide: any) {
    var session = localStorage.getItem('sessionId')
    //var learningid = localStorage.getItem('learningId')
    let payload =
    
    {
      "question": slide,
     "email": this.user.email,
      "time": this.user.created_at,
      "sessionId": session,
      "position": num,
      "voice": "en-US-AndrewNeural"
    }

    this.service.presentationspeak(payload).subscribe({
      next: (res: any) => {
        this.isLoadingNext = false
        this.isLoadingPrevious = false
        this.isCommonDisableButton = false
        if (res?.statusCode == 401) {
          this.isSwal = true;
          this.type = 'error';
          this.swalFireData = { title: res.body, showDenyButton: false, showCancelButton: false, confirmButtonText: 'OK', allowOutsideClick: false, timer: 6000 };
          this.payload = ''


        }
      },
      error: (e) => {
        console.error(e);
        this.isLoadingNext = false
        this.isLoadingPrevious = false
        this.isCommonDisableButton = false
      }

    })
  }

  
  // Function to refresh the current slide
  refreshCurrentSlide() {

    this.stopSubtitle.emit()
   
    this.isCommonDisableButton = true
    setTimeout(() => {
      this.isCommonDisableButton = false
    }, 5000)
    this.sendData(this.page, "←previous")

  }



  countAudioData(value: any, num: any) {
    // var session = localStorage.getItem('sessionId')
    var learningid = localStorage.getItem('learningId')
    let payload = {
      "email": this.user.email,
      "index": num,
      "id": learningid,
      "trigger": value,
      "token": this.AuthToken,
    }
    this.service.postAPI(`${environment.baseUrl}${API.audioCount}`, payload).subscribe((res: any) => {

      if (res?.statusCode == 401) {
        this.isSwal = true;
        this.type = 'error_2';
        this.swalFireData = { title: res.body, showDenyButton: false, showCancelButton: false, confirmButtonText: 'OK', allowOutsideClick: false, timer: 6000 };
        this.payload = ''

      }
    })
  }



  downloadPDF() {
    this.countAudioData('Download', this.page)
  }


//to download url 
  downloadPDFUrl() {

    if (this.contentType == "PDF") {
      const url = this.pdfSrc
      const filename = this.pName + ".pdf";

      this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
        const blobURL = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = blobURL;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(blobURL);
      });
    } else if (this.contentType == "Multimedia") {
      let url

      if (this.pdfSrctype == "youtube") {

        url = this.pdfYouTube
        this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
          const blobURL = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = blobURL;
          anchor.target = '_blank';
          anchor.download = 'youtube.mp4';
          anchor.click();
          window.URL.revokeObjectURL(blobURL);
        });
      } else if (this.pdfSrctype === 'jpeg' || this.pdfSrctype === 'jpg' || this.pdfSrctype === 'png' || this.pdfSrctype === 'gif') {
        url = this.pdfSrc
        const a = document.createElement('a');
        a.href = url;
        a.download = 'image.gif'; // Set the filename here if needed
        a.target = '_blank'; // Open link in a new tab
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }else if(this.pdfSrctype == 'mp4'){
        url = this.pdfSrc
        this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
        const blobURL = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = blobURL;
        anchor.target = '_blank';
        anchor.download = 'video.mp4';
        anchor.click();
        window.URL.revokeObjectURL(blobURL);
      });

      }

    }


  }

  // swal confirm dialog bog dynamic function
  swalDataFun(event:any){
    console.log(event)
    if(event?.isConfirmed){
      switch(event?.type){
        case "error_2":
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("Avatar");
          this.router.navigate(['/'])
          break;
        case "error":
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("Avatar");
          this.router.navigate(['/'])
          this.service.currentSharedData.subscribe((res: any) => {
            res.endSession();
          })
          break;
      }
    }
    this.isSwal = false;
  }

  ngOnDestroy(): void {
    this.page = 0
    this.totalPages = 0
  }

}
