import { Component, OnInit,ElementRef,ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-b2b-institution',
  templateUrl: './b2b-institution.component.html',
  styleUrls: ['./b2b-institution.component.css']
})
export class B2bInstitutionComponent implements OnInit {
  isSuccessVisible:boolean = false
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private router :Router,private titleService: Title, private metaService: Meta, private el :ElementRef,private service :UserService) {  }
  videoLinks: string[] = [
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/no-volume-ever-intro-vid.mp4',
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/dr-emma-meet-ai-tutors.mp4',
'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/eddie-meet-ai-tutors.mp4'
    
  ];


  currentVideoIndex = 0;
  isBrowser: boolean =false;
  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef<HTMLVideoElement> | any;


  ngOnInit(): void {
    this.titleService.setTitle('edYOU - Educational Institutions');
    this.metaService.addTags([
      {name: 'description', content: "Transform your school with edYOU's AI-powered learning platform. Trusted by alumni of top institutions like Oxford and Stanford, edYOU personalizes education by aligning directly with your curriculum. With tools like the Proprietary Ingestion Engine (PIE) and Intelligent Curation Engine (ICE), edYOU provides 24/7 flipped classroom support, real-time analytics, and test preparation. Experience a 15% boost in student performance while reducing teacher workload. Elevate education with ethical, personalized AI designed to improve outcomes for students, parents, and educators alike."},
      {name: 'keywords', content: 'edYOU for schools, AI-powered education, flipped classroom, personalized learning, school curriculum integration, real-time analytics, test prep platform, academic improvement, AI for educators, curriculum curation, ethical AI in education, PIE technology, ICE technology, educational outcomes, AI tutors for schools, innovative learning solutions, boosting student performance, teacher tools, Sierra Canyon case study, top schools trusted AI platform'},
    ])
    
   
    this.isBrowser = isPlatformBrowser(this.platformId);
    const videoIds = ['#backgroundVideo', '#laptop'];
    videoIds.forEach((id) => {
      const videoElement = this.el.nativeElement.querySelector(id) as HTMLVideoElement;
      if (videoElement) {
        videoElement.muted = true; // Required for autoplay in modern browsers
        videoElement.playsInline = true; // For mobile devices
        videoElement.autoplay = true;
    
        videoElement
          .play()
          .then(() => {
            console.log(`Video ${id} is playing.`);
          })
          .catch((error) => {
            console.error(`Video playback failed for ${id}:`, error);
          });
      } else {
        console.error(`Video element not found for selector: ${id}`);
      }
    });
  }
  nextVideo() {
    if (this.currentVideoIndex < this.videoLinks.length - 1) {
      this.currentVideoIndex++;
    } else {
      this.currentVideoIndex = 0;
    }
    this.updateVideoSource();
  }

  previousVideo() {
    if (this.currentVideoIndex > 0) {
      this.currentVideoIndex--;
    } else {
      this.currentVideoIndex = this.videoLinks.length - 1;
    }
    this.updateVideoSource();
  }

   updateVideoSource() {
    const videoElement = this.videoPlayer.nativeElement;
    videoElement.src = this.videoLinks[this.currentVideoIndex];
    videoElement.load();
    videoElement.play();
  }

  navigateToAIAvatar(){
    this.router.navigate(['/AI_Tutor'] ,{
      state: { from: 'b2b' }})
  }
// using it to trigger  login popup
openLoginPopup(event: any, isLogin: boolean): void {
  event.stopPropagation();
  this.service.showPopup(isLogin);
}
 
  navigateToEI(){
    this.router.navigate(['/educational-institute'])
  }
  showSuccessModal() {
    this.isSuccessVisible = true;
  }

  calendly() {
    if(this.isBrowser){
    window.open(this.service.salesLink());
    }
  }
  //App link from which user can start learning
  linkToedyouUser() {
   
   
    if(this.isBrowser){
    // window.location.href = 
    // window.open('https://smdev.edyou.com/signup', '_blank');
    window.open('https://ever-app.edyou.com/signup','_blank');
    }
  
  }
}
