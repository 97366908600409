<div class="container">
    <div class="missioncontainer">
        <video autoplay muted loop playsInline webkit-playsinline id="backgroundVideo" class="background-video">
            <source src="../../assets/video/course-bg.mov" >
        </video>
        <h2 class="missionHeader">Mission</h2>
        <div class="missionContent">
            <p class="missionparagraph">At edYOU, we are revolutionizing tutoring with safe Conversational AI Beings &reg;(CABs) who
                make learning more engaging and effective. Our platform delivers the best of personalized tutoring to
                help learners ace test prep, boost grades, advance professionally with certifications, and elevate their
                skills in sports through AI coaching.</p>

            <p class="missionparagraph">
                By improving learning outcomes with data-driven insights, we empower students, educators, and
                professionals to achieve more—easily and efficiently. Together, we’re creating a brighter future for
                global education, sparking curiosity, and bringing joy back into the learning journey.
            </p>
        </div>

    </div>
    <!-- Team Board -->

    <div class="Teamcontainer onlyteamheight">
        <h2 class="teamsHeader">edYOU Team</h2>
        <div class="teamMembercontainer">
          <div class="teammember" *ngFor="let team of team_member">
            <img 
              src="{{team.image}}" 
              class="teammemberImg" 
              [ngStyle]="!team.content ? {'pointer-events':'none'} : {'cursor': 'pointer'}"
              (click)="team.content && openPopup(team)" alt={{team.name}}>
            <h5 class="teamMembername">{{team.name}}</h5>
            <h6 class="teammemberposition">{{team.designation}}​</h6>
          </div>
        </div>
    </div>

    <div class="Teamcontainer academicbackground">
        <div class="imageoverlay"></div>
        <h2 class="teamsHeader">Academic Advisory Board</h2>
        <div class="teamMembercontainer">
          <div class="teammember" *ngFor="let team of Academic_board">
            <img 
              src="{{team.image}}" 
              class="teammemberImg" 
              [ngStyle]="!team.content ? {'pointer-events':'none'} : {'cursor': 'pointer'}"
              (click)="team.content && openPopup2(team)"
              alt="Photo of Academic Advisory Board Member, {{team.name}}"
              >
            <h5 class="teamMembername">{{team.name}}</h5>
            <h6 class="teammemberposition">{{team.designation}}​</h6>
          </div>
        </div>
    </div>

    <div class="clints">
        <div class="clintsimageoverlay"></div>
        <h2 class="clintsHeader">Clients and Partners</h2>
        <div class="clintsCardcontainer">
            <div class="clintCard">
                <div class="clintCardImg" >
                    <img src="../../assets/about-us/rm-logo-white.png" alt="Logo of our client and partner, Residents Medical">
                </div>
                
            </div>
            <div class="clintCard">
                <div class="clintCardImg" >
                    <img src="../../assets/about-us/image 52.png" alt="Logo of our client and partner, Harper Collins Publishers">
                </div>
            </div>
            <div class="clintCard">
                <div class="clintCardImg" >
                    <img src="../../assets/about-us/image 53.png" alt="Logo of our client and partner, ASCP, the American Society of Clinical Psychopharmacology">
                </div>
            </div>
            <div class="clintCard">
                <div class="d" >
                    <img src="../../assets/about-us/Sierra Canyon_White_Logo 2.png" alt="Logo of our client and partner, Sierra Canyon School">
                </div>
            </div>
            <div class="clintCard">
                <div class="clintCardImg" >
                    <img src="../../assets/about-us/image 54.png" alt="Logo of our client and partner, California Northstate University">
                </div>
            </div>
            <div class="clintCard">
                <div class="clintCardImg" >
                    <img src="../../assets/about-us/image 55.png" alt="Logo of our client and partner, HCA Healthcare, Hospital Corporation of America">
                </div>
            </div>
        </div>
    </div>


    <div class="payForwordsection">
        <h2 class="payForwordsectionHeader">Pay It Forward</h2>
        <div class="payforwordimage"></div>
        <div class="payforwordcontent">
            <p>edYOU was founded to empower individuals through education, making learning joyful, effective, and accessible. Driven by this mission, Dr. Michael Everest, our Founder and CEO, launched the Pay It Forward pledge: for every institution subscribing to edYOU, we provide free access to an underserved school in the developing world. Additionally, every 20 subscriptions in developed nations sponsor one for an underprivileged learner.</p>
            <p>Our technology opens doors to the future, and our mission ensures they’re open to everyone. Together, we’re bringing the light of learning to the world—progress starts with paying it forward.</p>
            <!-- <p>In addition, for every 20 annual subscriptions in developed nations, our Pay It Forward pledge will grant a fully subsidized subscription to an underprivileged learner. We firmly believe that if one of us can dream, all of us can dream.</p>
            <p>Our technology opens doors to the future. But our mission ensures those doors are open to everyone. Pay It Forward makes this win-win vision a reality. As an American company, we strive to uplift all people – especially the disadvantaged. Together, step by step, we will bring the light of learning to the world. Progress starts with paying it forward.</p> -->
        </div>
    </div>

    <div class="faqsection">
        <h2 class="payForwordsectionHeader faqmargin">Frequently Asked Questions</h2>

        <div class="accordion-item">
            <input type="checkbox" id="question1">
            <label class="accordion-header" for="question1">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>Is edYOU safe for my child?</span>
              
            </label>
            <div class="accordion-content">
              <p>We take learner safety very seriously. Our Intelligent Curation Engine uses automation and human reviewers to block inappropriate content. Educators have full visibility into conversations.</p>
            </div>
        </div>

        <div class="accordion-item">
            <input type="checkbox" id="question2">
            <label class="accordion-header" for="question2">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>How does edYOU’s AI work?</span>
              
            </label>
            <div class="accordion-content">
              <p>Our AI uses natural language processing to have personalized conversations tailored to each learner's interests and knowledge. It doesn't simply regurgitate internet information.</p>
            </div>
        </div>

        <div class="accordion-item">
            <input type="checkbox" id="question3">
            <label class="accordion-header" for="question3">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>What makes edYOU different than other education apps?</span>
              
            </label>
            <div class="accordion-content">
              <p>Our conversational AI beings build mentor-like relationships with students to provide truly customized education. We focus on transparency and ethical AI deployment.</p>
            </div>
        </div>
        <div class="accordion-item">
            <input type="checkbox" id="question4">
            <label class="accordion-header" for="question4">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>How does edYOU impact learning outcomes?</span>
              
            </label>
            <div class="accordion-content">
              <p>By providing adaptive and engaging education, edYOU increases knowledge retention, improves grades, and makes students excited to learn.</p>
            </div>
        </div>
        <div class="accordion-item">
            <input type="checkbox" id="question5">
            <label class="accordion-header" for="question5">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>Can edYOU integrate with our existing tools?</span>
              
            </label>
            <div class="accordion-content">
              <p>Yes, edYOU has APIs that allow easy integration with common education software, learning management systems, and enterprise tools.</p>
            </div>
        </div>
        <div class="accordion-item">
            <input type="checkbox" id="question6">
            <label class="accordion-header" for="question6">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>Is there live human support if I have an issue?</span>
              
            </label>
            <div class="accordion-content">
                <p>Yes, we have dedicated support agents available 24/7 via in-app chat, email, and phone support. Students and parents can always reach a real person.</p>
            </div>
        </div>
        <div class="accordion-item">
            <input type="checkbox" id="question7">
            <label class="accordion-header" for="question7">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>How does edYOU handle student privacy and security?</span>
              
            </label>
            <div class="accordion-content">
                <p>We take data privacy very seriously. All conversations are encrypted. Student data is anonymized and aggregated before internal use. Our practices follow all applicable regulations.</p>
            </div>
        </div>
        <div class="accordion-item">
            <input type="checkbox" id="question8">
            <label class="accordion-header" for="question8">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>What credentials do your team have?</span>
              
            </label>
            <div class="accordion-content">
                <p>Our team consists of PhD scientists, former teachers, child development experts, data ethicists, and experienced AI researchers and engineers.</p>
            </div>
        </div>
        <div class="accordion-item">
            <input type="checkbox" id="question9">
            <label class="accordion-header" for="question9">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>How do you ensure educational content stays up-to-date?</span>
              
            </label>
            <div class="accordion-content">
                <p>Our content team continuously reviews and updates study materials to reflect the latest educational standards and scientific knowledge. We also leverage automation and APIs.</p>
            </div>
        </div>
        <div class="accordion-item">
            <input type="checkbox" id="question10">
            <label class="accordion-header" for="question10">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>Can parents monitor conversations?</span>
              
            </label>
            <div class="accordion-content">
                <p>Yes, the edYOU parent dashboard allows you to view transcripts and recordings of your child's sessions, providing transparency into lessons.</p>
            </div>
        </div>

        <div class="accordion-item">
            <input type="checkbox" id="question11">
            <label class="accordion-header" for="question11">
              <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
              <span>How is edYOU different from ChatGPT and why is it a superior learning tool?</span>
              
            </label>
            <div class="accordion-content">
                <p>edYOU is fundamentally different from ChatGPT, delivering a superior learning experience through its proprietary technologies, PIE (Proprietary Ingestion Engine) and ICE (Intelligent Curation Engine). Unlike ChatGPT’s broad, unstructured responses, edYOU curates information from curriculum-aligned materials, ensuring zero hallucinations and academic integrity. Its teacher-driven methodology guides learners step-by-step through expertly designed content. edYOU discourages plagiarism, focusing on adaptive, personalized mastery. Students gain trusted, curriculum-based support for real understanding and success.</p>
            </div>
        </div>
        <div class="hrline"></div>
    </div>
</div>









<div class="overlay show" *ngIf="isPopupVisible" (click)="closePopup()"></div>
<div class="teamPoup show" *ngIf="isPopupVisible">
    <div class="closePoupcard" (click)="closePopup()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35" fill="none">
            <path d="M2.55078 2.32593L32.4297 32.2049" stroke="white" stroke-width="4" stroke-linecap="round" />
            <path d="M32.8203 2.60083L2.94136 32.4798" stroke="white" stroke-width="4" stroke-linecap="round" />
        </svg>
    </div>
    <ng-container *ngIf="selectedCard">
        <h4 class="teampoupHeader">{{ selectedCard.name }}</h4>
        <h5 class="teampoupdesignation">{{ selectedCard.designation }}</h5>
        <div class="devider"></div>
        <div class="conatinerText">
            <p [innerHTML]="selectedCard.content"></p>
        </div>
    </ng-container>
</div>



<div class="overlay show" *ngIf="isPopupVisible2" (click)="closePopup2()"></div>
<div class="teamPoup show" *ngIf="isPopupVisible2">
    <div class="closePoupcard" (click)="closePopup2()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35" fill="none">
            <path d="M2.55078 2.32593L32.4297 32.2049" stroke="white" stroke-width="4" stroke-linecap="round" />
            <path d="M32.8203 2.60083L2.94136 32.4798" stroke="white" stroke-width="4" stroke-linecap="round" />
        </svg>
    </div>
    <ng-container *ngIf="selectedCard">
        <h4 class="teampoupHeader">{{ selectedCard.name }}</h4>
        <h5 class="teampoupdesignation">{{ selectedCard.designation }}</h5>
        <div class="devider"></div>
        <div class="conatinerText">
            <p [innerHTML]="selectedCard.content"></p>
        </div>
    </ng-container>
</div>



<!-- 
<p>With over 20 years experience blending creative arts and technology, Owain Yeoman serves as Chief Commercial Officer at edYOU Technologies.</p>
<p>As an award-winning actor and producer, he built a multi-million dollar business, contributing to globally successful media content recognized with Emmys, Golden Globes, and Oscars. Holding a BA and MA from Oxford University, a Graduate Diploma from The Royal Academy of Dramatic Art, and a BA from Kings College London, Owain combines creative expertise with strategic insight.</p>
<p>Coming from a family of educators, he is deeply committed to fostering a supportive learning environment where wellness is key. At edYOU, Owain leads the sales, marketing, and technical teams, driving innovation in AI learning and wellness while expanding the company’s presence across various industries.</p>
<p>With over 20 years experience blending creative arts and technology, Owain Yeoman serves as Chief Commercial Officer at edYOU Technologies.</p>
<p>As an award-winning actor and producer, he built a multi-million dollar business, contributing to globally successful media content recognized with Emmys, Golden Globes, and Oscars. Holding a BA and MA from Oxford University, a Graduate Diploma from The Royal Academy of Dramatic Art, and a BA from Kings College London, Owain combines creative expertise with strategic insight.</p>
<p>Coming from a family of educators, he is deeply committed to fostering a supportive learning environment where wellness is key. At edYOU, Owain leads the sales, marketing, and technical teams, driving innovation in AI learning and wellness while expanding the company’s presence across various industries.</p> -->