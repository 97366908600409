import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerLoaderService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  loadPdfViewer() {
    return new Promise<any>((resolve, reject) => {
      if (isPlatformBrowser(this.platformId)) {
        import('ng2-pdf-viewer')
          .then((module) => {
           
            resolve(module);
          })
          .catch((error) => {
        
            reject(error);
          });
      } else {
        resolve(null); // In SSR, just resolve with null
      }
    });
  }
}
