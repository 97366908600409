// forbidden-password.validator.ts (you can name the file as needed)
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Custom validator to check for forbidden characters in password
export function forbiddenPasswordCharacters(): ValidatorFn {
  const forbiddenChars = /[&=/"<>?'\s]/; // Regex for unwanted characters
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && forbiddenChars.test(control.value)) {
      return { forbiddenPassword: { value: control.value } };
    }
    return null; // Return null if no forbidden characters are found
  };
}
