<div class="mainDIv">

  <div class="hero-section-enterprise">
    <!-- Video Background -->
    <video autoPlay loop muted playsInline webkit-playsinline class="background-video"  id="backgroundVideo" >
      <source src="../../assets/video/LandingPageVideo_4.mp4"
        >
      Your browser does not support the video tag.
    </video>
    <!-- Overlay and Content -->
    <div class="overlay"></div>
      <div class="hero-content">
        <h1>Elevate Your School with edYOU</h1>


        <div class="mobilebtn-h">
          <button class="cta-button"(click)="linkToedyouUser()"><span class="innerTextbtn" > Try Free Now
            </span></button>
        </div>
        <div class="mobilebtn-t">

        </div>
      </div>
    <!-- </div> -->
  </div>


  <!-- <div class="banner">
    <div class="div-container">
      <div class="scrolling-belt">
        <img class="" src="https://c.animaapp.com/fXulaZA9/img/image-54@2x.png" />
        <img class="-4" src="https://c.animaapp.com/fXulaZA9/img/image-60@2x.png" />
        <img class="-3" src="https://c.animaapp.com/fXulaZA9/img/image-58@2x.png" />
        <img class="-6" src="https://c.animaapp.com/fXulaZA9/img/image-59@2x.png" />
        <img class="-7" src="https://c.animaapp.com/fXulaZA9/img/image-61@2x.png" />
        <img class="-5" src="https://c.animaapp.com/fXulaZA9/img/image-57@2x.png" />

      
      </div>
    </div>
  </div> -->


  <div class="banner">
    <div class="div-container">
      <div class="scrolling-belt">
        
        <img class="-5" src="https://c.animaapp.com/fXulaZA9/img/image-57@2x.png" alt="Oxford"/> <!-- Oxford -->
        <img class="cornell-logo" src="./assets/logos-institutions/cornell-logo.png" alt="Cornell-logo" />
        <img class="harvard-logo" src="./assets/logos-institutions/harvard-logo-white.png" alt="Harvard-logo" />
        <img class="-6" src="https://c.animaapp.com/fXulaZA9/img/image-59@2x.png" alt="Columbia"/> <!-- Columbia -->
        <img class="stanford-logo" src="./assets/logos-institutions/stanford-white.png"  alt="Stanford-logo" />
        <img class="-7" src="https://c.animaapp.com/fXulaZA9/img/image-61@2x.png" alt="Dartmouth" /> <!-- Dartmouth -->
        <!-- <img class="-4" src="https://c.animaapp.com/fXulaZA9/img/image-60@2x.png" /> 
        <img class="" src="https://c.animaapp.com/fXulaZA9/img/image-54@2x.png" />  -->

      </div>
    </div>
  </div>


  <div class="banner-bottom">
    <h2>*As trusted by alumni of</h2>
  </div>
  <!-- edyou intro  -->
  <section class="edyouwork">
    <div class="container">
      <div class="content">
        <h1>How the edYOU Platform Works</h1>
        <ul class="features-list">
          <li>Choose your customized courses.</li>
          <li>Engage with personalized AI tutors 24/7.</li>
          <li>Test your knowledge with infinite test prep.</li>
          <li>Monitor progress with real-time analytics.</li>
          <li>Improve grades & earn certification.</li>
        </ul>
      </div>

      <div class="image-container">
        <img src="../../assets/newDesignImage/Macbook.png" alt="Laptop showing platform demo"  class="laptop-mockup">
        <video id="laptop" class="overlay-video"autoPlay loop muted playsInline webkit-playsinline>
          <source src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/no_zoom_emma.mp4"
            type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>

    </div>
  </section>
  <!--  -->
  <!-- trynow avatarppt v2 not required-->
  <!-- <div class="avmain">
    <div class="avcontainer">
         <button (click)="navigateToAIAvatar()"  *ngIf="!isSuccessVisible" class="cta-button"><span class="innerTextbtn" > Try Free Now </span></button>
      <div  *ngIf="isSuccessVisible">
       
          <div class="successcard">
            <p>Thank you for trying edYOU!</p>
            <button (click)="calendly()">
              Contact Sales</button>
          </div>  
      </div>

    </div>
  </div> -->







  <!-- gallery -->

  <div class="outer-card">
    <div class="section-title"><h2>How edYOU Works for Your School</h2></div>

    <div class="content-section ">
      <!-- Students - Content Left, Image Right -->
      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrow">
          <div class="card-content rightPadding">
            <div class="description">
              <p>
                edYOU ingests curriculum materials provided by schools, such as textbooks and lesson plans, for digital
                processing.
              </p>
            </div>
          </div>
          <img class="rightimage" src="../../assets/newb2cmedia/instcard1.png" alt="Students Image">
        </div>
      </div>

      <!-- Parents - Image Left, Content Right -->
      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrowreverse">
          <div class="card-content reverse-padding">
            <div class="description">
              <p>
                The Proprietary Ingestion Engine (PIE) organizes this content into structured “Course” lessons, quizzes,
                and exercises making the school curriculum the direct learning repository from which our Conversational
                AI beings are trained. edYOU now serves as a 24/7 “flipped classroom” reinforcing the same teaching
                taking place in class.
              </p>
            </div>
          </div>
          <img class="reverse-img" src="../../assets/newb2cmedia/instcard2.png" alt="Parents Image">
        </div>
      </div>

      <!-- Professionals - Content Left, Image Right -->
      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrow">
          <div class="card-content rightPadding">
            <div class="description">
              <p>
                The Intelligent Curation Engine (ICE) refines the curriculum-aligned materials to ensure they are error
                and toxicity free and curated to the appropriate grade and learning level.
              </p>
            </div>
          </div>
          <img class="rightimage" src="../../assets/newb2cmedia/instcard3.png" alt="Professionals Image">
        </div>
      </div>

      <!-- Athletes - Image Left, Content Right -->
      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrowreverse">
          <div class="card-content reverse-padding ">
            <div class="description">
              <p>
                edYOU’s dashboard delivers real-time insights, tracking progress, test scores, and learning activity. It
                grades tests instantly, saving teachers time and guiding students with data-driven precision to improve
                performance
              </p>
            </div>
          </div>
          <img class="reverse-img" src="../../assets/newb2cmedia/instcard4.png" alt="Athletes Image">
        </div>
      </div>
    </div>
    <!-- <p class="bottomLine">This ensures students study exactly what’s taught in class, with real-time oversight for
      teachers and parents.</p> -->
  </div>

  <!-- research -->
  <div class="research">
    <div class="research-container">
      <h2>Research-Proven Results</h2>
      <p>Students using edYOU saw a 15% increase in test results*</p>
      <div class="research-img">
        <img src="../../assets/newb2cmedia/research-img.png" alt="research">
      </div>
      <a href="https://edyou.com/assets/transforming-education-edyou-white-paper-1.0.pdf"
        target="_blank" title="ransforming Education with edYOU Conversational AI:
        A Case Study of Sierra Canyon School">*(Sanchez-Gonzalez, M. A. (2024, August 1). Transforming Education with edYOU Conversational AI:
        A Case Study of Sierra Canyon School.) </a>
    </div>
  </div>





  <!-- Video Background -->
  <div class="bottom-sc">
    <video autoPlay loop muted playsInline webkit-playsinline class="background-video">
      <source  src="https://pollydemo2022.s3.us-west-2.amazonaws.com/new-b2c/course-bg.mov"
        type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <!-- Overlay and Content -->
    <div class="overlay-bottom">
      <div class="bottom-content">
        <h2>Ready to bring edYOU’s AI-powered learning to your school? </h2>


        <div>
          <button class="cta-button" (click)="calendly()"><span class="innerTextbtn" (click)="calendly()"> Contact Sales
            </span></button>
        </div>

      </div>
    </div>
  </div>
  <!-- bio -->
  <div class="bio-section">
    <hr>
    <div class="scbio">
      <div class="card1">
        <div class="child1">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
        </div>
        <div class="child2">
          <p>“edYOU is the future of learning. We are happy to utilize it at Sierra Canyon.”</p>
        </div>
        <div class="child3">
          <h5 class="headofSchool">Jim Skrumbis </h5>
          <h6 class="headofSchoolposition">Head of School at Sierra Canyon</h6>
        </div>
      </div>
      <div class="card2">
        <img src="./assets/newb2cmedia/Jim-Skrumbis-1.png" alt="">
      </div>
    </div>
    <hr>
    <div class="scbio">
      <div class="card1">
        <div class="child1">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
        </div>
        <div class="child2">
          <p>“My medical residents improved their exam scores greatly by using edYOU for their ITE prep.”</p>
        </div>
        <div class="child3 forIpadchild3">
          <h5 class="headofSchool">Salman Muddassir,&nbsp; MD, &nbsp;FACP </h5>
          <h6 class="headofSchoolposition">Internal Medicine Program Director</h6>
          
        </div>
      </div>
      <div class="card2">
        <img src="./assets/newb2cmedia/salman.png" alt="">
      </div>
    </div>
    <hr>
  </div>
  <!-- testimonial -->

  <app-testimonial-card></app-testimonial-card>

  <!-- patent -->
  <section class="patent">
    <div class="patent1">

      <h2>edYOU’s Patented Technology </h2>

      <img src="../../assets/newb2cmedia/patent-1.png" alt="Icon A" class="icon">
    

      <h3>Proprietary Ingestion Engine</h3>

      <p>
        PIE powers edYOU’s learning platform, processing vast data to enable personalized education. Using machine
        learning and human insight, PIE extracts key insights, generates quizzes, and tags metadata to make content
        interactive.<br><br>
        A human-in-the-loop approach ensures all content aligns with educational standards before reaching learners. PIE
        then hands off curated information to the Personalized AI module (PAl) for tailored, conversational
        learning.<br><br>
        This seamless integration transforms limitless information into personalized knowledge, redefining education for
        the digital age.
      </p>
    </div>
    <div class="patent1">

      <img src="../../assets/newb2cmedia/patent-2.png" alt="Icon A" class="icon">
      <h3>Intelligent Curation Engine</h3>
      <p>
        The Intelligent Curation Engine (ICE) ensures safe, ethical AI by safeguarding against toxic content,
        misinformation, and bias. Combining metadata and human oversight, ICE curates information for edYOU learners,
        flags inappropriate content, and blocks offensive language.<br><br>

        ICE tracks data origins, offering transparency and empowering educators to adjust AI responses. Its
        security-first approach prevents hacking and ensures diverse, trustworthy training data.
        <br><br>
        ICE reflects our commitment to ethical, transparent AI. By prioritizing human accountability, it sets a new
        standard for responsible technology that uplifts and aligns with educators’ values.
      </p>
    </div>
  </section>
  <!-- faq -->
  <section class="faq">
    <div class="faqsection">
      <h2 class="payForwordsectionHeader faqmargin">Frequently Asked Questions</h2>

      <div class="accordion-item">
        <input type="checkbox" id="question1">
        <label class="accordion-header" for="question1">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>Is edYOU safe for my child?</span>

        </label>
        <div class="accordion-content">
          <p>We take learner safety very seriously. Our Intelligent Curation Engine uses automation and human reviewers
            to block inappropriate content. Educators have full visibility into conversations.</p>
        </div>
      </div>

      <div class="accordion-item">
        <input type="checkbox" id="question2">
        <label class="accordion-header" for="question2">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How does edYOU’s AI work?</span>

        </label>
        <div class="accordion-content">
          <p>Our AI uses natural language processing to have personalized conversations tailored to each learner's
            interests and knowledge. It doesn't simply regurgitate internet information.</p>
        </div>
      </div>

      <div class="accordion-item">
        <input type="checkbox" id="question3">
        <label class="accordion-header" for="question3">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>What makes edYOU different than other education apps?</span>

        </label>
        <div class="accordion-content">
          <p>Our conversational AI beings build mentor-like relationships with students to provide truly customized
            education. We focus on transparency and ethical AI deployment.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question4">
        <label class="accordion-header" for="question4">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How does edYOU impact learning outcomes?</span>

        </label>
        <div class="accordion-content">
          <p>By providing adaptive and engaging education, edYOU increases knowledge retention, improves grades, and
            makes students excited to learn.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question5">
        <label class="accordion-header" for="question5">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>Can edYOU integrate with our existing tools?</span>

        </label>
        <div class="accordion-content">
          <p>Yes, edYOU has APIs that allow easy integration with common education software, learning management
            systems, and enterprise tools.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question6">
        <label class="accordion-header" for="question6">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>Is there live human support if I have an issue?</span>

        </label>
        <div class="accordion-content">
          <p>Yes, we have dedicated support agents available 24/7 via in-app chat, email, and phone support. Students
            and parents can always reach a real person.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question7">
        <label class="accordion-header" for="question7">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How does edYOU handle student privacy and security?</span>

        </label>
        <div class="accordion-content">
          <p>We take data privacy very seriously. All conversations are encrypted. Student data is anonymized and
            aggregated before internal use. Our practices follow all applicable regulations.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question8">
        <label class="accordion-header" for="question8">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>What credentials do your team have?</span>

        </label>
        <div class="accordion-content">
          <p>Our team consists of PhD scientists, former teachers, child development experts, data ethicists, and
            experienced AI researchers and engineers.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question9">
        <label class="accordion-header" for="question9">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How do you ensure educational content stays up-to-date?</span>

        </label>
        <div class="accordion-content">
          <p>Our content team continuously reviews and updates study materials to reflect the latest educational
            standards and scientific knowledge. We also leverage automation and APIs.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question10">
        <label class="accordion-header" for="question10">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>Can parents monitor conversations?</span>

        </label>
        <div class="accordion-content">
          <p>Yes, the edYOU parent dashboard allows you to view transcripts and recordings of your child's sessions,
            providing transparency into lessons.</p>
        </div>
      </div>

      <div class="accordion-item">
        <input type="checkbox" id="question11">
        <label class="accordion-header" for="question11">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How is edYOU different from ChatGPT and why is it a superior learning tool?</span>
          
        </label>
        <div class="accordion-content">
            <p>edYOU is fundamentally different from ChatGPT, delivering a superior learning experience through its proprietary technologies, PIE (Proprietary Ingestion Engine) and ICE (Intelligent Curation Engine). Unlike ChatGPT’s broad, unstructured responses, edYOU curates information from curriculum-aligned materials, ensuring zero hallucinations and academic integrity. Its teacher-driven methodology guides learners step-by-step through expertly designed content. edYOU discourages plagiarism, focusing on adaptive, personalized mastery. Students gain trusted, curriculum-based support for real understanding and success.</p>
        </div>
      </div>
      <div class="hrline"></div>
    </div>
  </section>

</div>
<!-- modal -->